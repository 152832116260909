import store from "../store/index";

// Only paid deposit - should be on background check
export const depositPaidMember = () => {
	store.commit("isPaidMemberAuth", true);
	store.commit("isLoginAuth", false);
	store.commit("isNewMemberAuth", false);
	store.commit("isSubscribedMemberAuth", false);
	store.commit("isMembershipSuccess", false);
	store.commit("isDepositSuccess", false);
};

// Full membership
export const fullMember = () => {
	store.commit("isLoginAuth", true);
	store.commit("isPaidMemberAuth", false);
	store.commit("isNewMemberAuth", false);
	store.commit("isSubscribedMemberAuth", false);
	store.commit("isMembershipSuccess", false);
	store.commit("isDepositSuccess", false);
};

// Hasn't paid anything
export const newMember = () => {
	store.commit("isNewMemberAuth", true);
	store.commit("isLoginAuth", false);
	store.commit("isPaidMemberAuth", false);
	store.commit("isSubscribedMemberAuth", false);
	store.commit("isMembershipSuccess", false);
	store.commit("isDepositSuccess", false);
};

// Background check passed
export const bgCheckPass = () => {
	store.commit("isSubscribedMemberAuth", true);
	store.commit("isNewMemberAuth", false);
	store.commit("isPaidMemberAuth", false);
	store.commit("isDepositSuccess", false);
	store.commit("isLoginAuth", false);
	store.commit("isMembershipSuccess", false);
};

// Markers - These aren't authorizations, just markers for when a user signs in after the initial sign in

export const depositSuccessMarker = () => {
	store.commit("isDepositSuccess", true);
	store.commit("isPaidMemberAuth", false);
	store.commit("isLoginAuth", false);
	store.commit("isNewMemberAuth", false);
	store.commit("isSubscribedMemberAuth", false);
	store.commit("isMembershipSuccess", false);
};

export const memberSuccessMarker = () => {
	store.commit("isMembershipSuccess", true);
	store.commit("isDepositSuccess", false);
	store.commit("isSubscribedMemberAuth", false);
	store.commit("isNewMemberAuth", false);
	store.commit("isPaidMemberAuth", false);
	store.commit("isLoginAuth", false);
};
