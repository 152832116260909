<template>
  <b-row>
    <load-screen v-show="loading" />
    <b-modal ref="profile-status-modal" id="profile-status-modal" hide-footer hide-header>
      <p>This email address is not authorized to access this site.</p>
      Contact <a style="color: #007bff" href="mailto:ask@carpedmdating.com" target="_blank">ask@carpedmdating.com</a> for support.
    </b-modal>

    <b-modal
      ref="profile-status-modal-in-review"
      id="profile-status-modal-in-review"
      hide-footer
      hide-header
    >
      <p>
        Hey, looks like your profile is still under review. Once our matchmakers
        approve your profile you'll be able to pay your deposit.
      </p>
      <a style="color: #007bff" :href="config.linkCalendar" target="_blank"
        >Check My Profile Status</a
      >
    </b-modal>

    <b-modal
      ref="unsubmitted-profile-modal"
      id="unsubmitted-profile-modal"
      hide-footer
      hide-header
    >
      <p>
        Hey, it looks like you haven't submitted your profile. Log in and submit.
      </p>
      <a style="color: #007bff" :href="config.linkCalendar" target="_blank"
        >Complete your profile</a
      >
    </b-modal>

    <b-col cols-6 lg="6">
      <div class="login__logo-container">
        <img src="@/assets/carpedm-color-logo.png" />
      </div>
    </b-col>
    <b-col class="login__form-bg" sm="12" lg="6" cols="12">
      <b-row class="login__layout">
        <b-col cols="12" sm="12" lg="10" style="align-self: center; padding: 0">
          <div class="login__container">
            <a href="https://carpedmdating.com/"><img src="@/assets/carpedm-color-logo.png" /></a>
            <b-form class="login__forms" inline>
              <ResetForm
                v-show="showResetForm"
                v-if="seen"
                v-bind:resetPassword="resetPassword"
                v-bind:inputResetPassword="input.resetPassword"
                v-bind:inputConfirmedPassword="input.confirmedPassword"
                v-bind:inputResetPasscode="input.resetPasscode"
                v-bind:reloadPage="reloadPage"
                v-bind:inputEmail="input.email"
                v-bind:resetErrorBlock="resetErrorBlock"
              />

              <TempCodeForm
                v-if="popup"
                v-bind:inputPasscode="input.passcode"
                v-bind:getTempCodeAuthorization="getTempCodeAuthorization"
                v-bind:resetTempCode="resetTempCode"
                v-bind:reloadPage="reloadPage"
                v-bind:resetErrorBlock="resetErrorBlock"
              />

              <div class="login__inner-container" v-else-if="!popup">
                <ForgotPassword
                  v-if="seen"
                  v-bind:forgotPassword="forgotPassword"
                  v-bind:showResetForm="showResetForm"
                  v-bind:seen="seen"
                  v-bind:cancelForgotPassword="cancelForgotPassword"
                />

                <!-- Default Login Form -->
                <div v-if="!seen" class="login__main-form">
                  <h1>Member Login</h1>

                  <b-form v-on:submit.prevent="login" class="input-container">
                    <div>
                      <label class="sr-only" for="inline-form-input-name">Email</label>
                      <b-form-input
                        required
                        class="inline-form-input-name mb-2 mr-sm-2 mb-sm-0"
                        placeholder="email"
                        type="email"
                        name="email"
                        v-model="input.email"
                        v-on:input="resetErrorBlock()"
                      ></b-form-input>
                    </div>
                    <div>
                      <label class="sr-only" for="inline-form-input-name">Password</label>
                      <b-form-input
                        required
                        class="inline-form-input-name mb-2 mr-sm-2 mb-sm-0"
                        placeholder="password"
                        type="password"
                        name="password"
                        v-model="input.password"
                        v-on:input="resetErrorBlock()"
                      ></b-form-input>
                    </div>
                    <b-button type="submit" variant="primary" v-on:submit="login()" class="btn-login-primary">Sign In</b-button>
                  </b-form>
                  <p style="cursor: pointer; color: #3242c0" v-on:click="seen = !seen">Forgot your password?</p>
                  <a style="cursor: pointer; color: #3242c0; text-decoration: none" :href="config.linkCalendar" target="_blank"
                    >Not a member? Create an account to get started.</a
                  >
                </div>
              </div>
              <MessageBlock v-if="errorInfo.trigger" v-bind:message="errorInfo.message" v-bind:status="errorInfo.status" />
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import endpoints from '../../utils/endpoints';
import MessageBlock from '../MessageBlock.vue';
import { formMessages } from '../../utils/formMessages';
import { basicPost, authGet } from '../../utils/fetchApi';
import { WriteCookie } from '../../utils/utilMethods';
import { depositPaidMember, depositSuccessMarker, newMember, fullMember } from '../../utils/userStatus';
import TempCodeForm from './TempCodeForm.vue';
import ResetForm from './ResetForm.vue';
import ForgotPassword from './ForgotPassword.vue';
import LoadScreen from '../LoadScreen.vue';

export default {
  name: 'LoginForm',
  data() {
    return {
      errorInfo: {
        trigger: false,
        message: '',
        status: '',
      },
      input: {
        email: '',
        password: '',
        resetPassword: '',
        confirmedPassword: '',
        resetPasscode: '',
        passcode: '',
      },
      seen: false,
      popup: false,
      showResetForm: false,
      errors: [],
      success: [],
      storage: {},
      sessionId: '',
      config: {
        vue_app_env: this.$config.vue_app_env,
        linkCalendar: this.$config.vue_app_register_url,
      },
      loading: false,
    };
  },
  computed: {
    loginAuth() {
      return this.$store.state.loginAuth;
    },
    newMemberAuth() {
      return this.$store.state.newMemberAuth;
    },
    paidMemberAuth() {
      return this.$store.state.paidMemberAuth;
    },
    subscribedMemberAuth() {
      return this.$store.state.subscribedMemberAuth;
    },
    depositSuccess() {
      return this.$store.state.depositSuccess;
    },
    membershipSuccess() {
      return this.$store.state.membershipSuccess;
    },
  },
  components: {
    MessageBlock,
    TempCodeForm,
    ResetForm,
    ForgotPassword,
    LoadScreen,
  },
  mounted: function () {
    // check customers status whenever on login component
    const vu = localStorage.getItem('vu');
    if (vu !== null) this.checkCustomerMarker();
  },
  methods: {
    showModalUnAuthorized() {
      this.$refs['profile-status-modal'].show();
      if (localStorage.getItem('vu') !== null && localStorage.getItem('u') !== null) {
        localStorage.removeItem('vu');
        localStorage.removeItem('u');
      }
    },
    showModalUnSubmitted() {
      this.$refs['unsubmitted-profile-modal'].show();
      if (localStorage.getItem('vu') !== null && localStorage.getItem('u') !== null) {
        localStorage.removeItem('vu');
        localStorage.removeItem('u');
      }
    },
    showModalStillInReview() {
      this.$refs['profile-status-modal-in-review'].show();
      if (localStorage.getItem('vu') !== null && localStorage.getItem('u') !== null) {
        localStorage.removeItem('vu');
        localStorage.removeItem('u');
      }
    },
    logout() {
      localStorage.removeItem('u');
      localStorage.removeItem('vu');
      window.location.reload();
    },
    cancelForgotPassword() {
      this.seen = !this.seen;
    },
    callErrorBlock(trigger, message, status) {
      this.errorInfo.trigger = trigger;
      this.errorInfo.message = message;
      this.errorInfo.status = status;
    },
    resetErrorBlock() {
      this.errorInfo.trigger = false;
      this.errorInfo.message = '';
      this.errorInfo.status = '';
    },
    clearForm() {
      this.password = '';
      this.input.confirmedPassword = '';
      this.passcode = '';
      this.input.resetPassCode = '';
      this.input.confirmedPassword = '';
      this.input.resetPassword = '';
    },
    login() {
      // pass in email and password for validation
      const email = this.input.email;
      const password = this.input.password;
      this.loginValidation(email, password);
    },
    loginValidation(email, password) {
      // validation regex
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line */
      const pw = /[^a-zA-Z0-9]/g; /* eslint-disable-line */
      const validEmail = email;
      const validPassword = password;
      const emailBool = re.test(email);

      // if email is true get temp code else throw error
      emailBool === true ? this.getTempCode(validEmail, validPassword) : this.callErrorBlock(true, formMessages.errors.login.invalid_credentials, 'error');
    },
    getTempCode(email, password) {
      const body = {};
      body.emailAddress = email;
      body.password = password;

      basicPost('post', endpoints.getTempCode(this.config.vue_app_env), body)
        .then((response) => response.json())
        .then((data) => {
          /* eslint-disable-line */
          if (data.authType === 'Failed') {
            if (data.message === "This email address is not authorized to access this site. Contact ask@carpedmdating.com for support.") {
              this.showModalUnAuthorized();
              return
            }
            this.callErrorBlock(true, formMessages.errors.login.invalid_name_password, 'error');
          } else {
            this.popup = !this.popup;
            this.input.email = body.emailAddress;
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    getTempCodeAuthorization(newPasscode) {
      this.input.passcode = newPasscode;
      const passcode = this.input.passcode;
      const email = this.input.email;
      const authBody = {};
      authBody.emailAddress = email;
      authBody.tempCode = parseInt(passcode);

      this.verifyUser(authBody);
    },
    verifyUser(authBody) {
      basicPost('post', endpoints.getSignIn(this.config.vue_app_env), authBody)
        .then((response) => response.json())
        .then((data) => {
          // set user creds
          localStorage.setItem('u', data.userId);
          localStorage.setItem('vu', data.token);
          const vu = localStorage.getItem('vu');
          const u = localStorage.getItem('u');
          const authType = data.authType;

          this.getProfileStatus(vu, u, authType);
        })
        .catch((error) => {
          console.log('Error:', error);
          this.callErrorBlock(true, formMessages.errors.login.invalid_credentials, 'error');
        });
    },
    getProfileStatus(vu, u, authType) {
      authGet('get', endpoints.getUserById(this.config.vue_app_env, u), vu)
        .then((response) => response.json())
        .then((data) => {
          const profileStatusKey = data.profileStatus.key;
          console.log(data);

          if (data.type !== 1 || data.status !== 1 || data.roles[0].id !== '4') {
            this.showModalUnAuthorized();
            return;
          }

          // check profile status
          if (profileStatusKey === 'INITIAL' || profileStatusKey === 'AMEND') {
            this.showModalUnSubmitted();
          } else if (profileStatusKey === 'SUBMITTED') {
            this.showModalStillInReview();
          } else if (
            profileStatusKey === 'REJECTED' ||
            profileStatusKey === 'INTERVIEWREJECTED' ||
            profileStatusKey === 'BCKREJECTED'
          ) {
            this.showModalUnAuthorized();
            this.logout();
          } else {
            authType === 'Success' ? this.logInUser() : this.callErrorBlock(true, formMessages.errors.login.invalid_passcode, 'error');
          }
        })
        .catch((error) => {
          console.log('Error:', error);
          this.callErrorBlock(true, formMessages.errors.login.invalid_credentials, 'error');
        });
    },
    logInUser() {
      const vu = localStorage.getItem('vu');

      if (vu) {
        authGet('get', endpoints.getPaymentInfo(this.config.vue_app_env), vu)
          .then((response) => response.json())
          .then((data) => {
            // instantiate cookie
            const writeCookie = new WriteCookie();
            writeCookie.getCookieInfo();

            if (data.membership !== null) {
              if (data.membership.customerId === 'string' || data.membership.customerId === null) {
                depositPaidMember();
              } else {
                fullMember();
                this.$router.push('/dashboard');
              }
            } else {
              // if membership is null, user is new
              newMember();
            }
          })
          .catch((error) => {
            console.log('Error:', error);
          });
      }
    },
    forgotPassword(email) {
      // get new email from forgot password component
      this.input.email = email;
      // change form view to reset form
      const vu = localStorage.getItem('vu');

      authGet('get', endpoints.getForgotPassword(this.config.vue_app_env, email), vu)
        .then((response) => response.json())
        .then((data) => {
          if (data.authType !== 'Success') {
            this.showResetForm = false;
            this.callErrorBlock(true, formMessages.errors.login.invalid_credentials, 'error');
          } else {
            this.showResetForm = true;
          }
        })
        .catch((error) => {
          this.showResetForm = !this.showResetForm;
          console.log('Error:', error);
        });
    },
    resetPassword(newPassword, passcode, confirmedPassword) {
      // reassign fields based on reset form changes
      this.input.resetPassword = newPassword;
      this.input.confirmedPassword = confirmedPassword;
      this.input.resetPasscode = passcode;
      const body = {};
      body.emailAddress = this.input.email;
      body.password = this.input.resetPassword;
      body.tempCode = parseInt(this.input.resetPasscode);

      basicPost('post', endpoints.getResetPassword(this.config.vue_app_env), body)
        .then((response) => response.json())
        .then((data) => {
          /* eslint-disable-line */
          if (data.authType === 'Failed') {
            this.callErrorBlock(true, formMessages.errors.login.invalid_new_password.message, 'error');
          } else if (data.status === 400) {
            this.callErrorBlock(true, formMessages.errors.login.invalid_fields, 'error');
          } else if (this.input.confirmedPassword === this.input.resetPassword && data.authType === 'Success') {
            this.callErrorBlock(true, formMessages.success.login.password_created, 'success');
            setTimeout(() => {
              this.reloadPage();
            }, 2000);
          } else if (this.input.confirmedPassword !== this.input.resetPassword && data.authType === 'Success') {
            this.callErrorBlock(true, formMessages.errors.login.invalid_matched_password, 'error');
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    checkCustomerMarker() {
      // TODO: add this the loader component state to prevent flashing screens
      // Call method on component mount if user tries to come back to login
      const vu = localStorage.getItem('vu');
      authGet('get', endpoints.getPaymentInfo(this.config.vue_app_env), vu)
        .then((response) => response.json())
        .then((data) => {
          if (data.membership) {
            // if membership obj is present
            if (data.membership.customerId === null || data.membership.customerId === 'string') {
              depositSuccessMarker();
            } else {
              fullMember();
            }
            // if membership obj isn't even there
          } else {
            newMember();
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    reloadPage() {
      window.location.reload();
    },
    resetTempCode() {
      const body = {};
      body.emailAddress = this.input.email;
      body.password = this.input.password;
      basicPost('post', endpoints.getTempCode(this.config.vue_app_env), body)
        .then((response) => response.json())
        .then((data) => {
          /* eslint-disable-line */
          return data;
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  height: 100%;
}

.login__form-bg {
  background: #3242c0;
}

.login__container {
  width: 100%;
}

.login__logo-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .input-container {
  padding: 20px 0 0 0;
}

::v-deep .input-container div {
  padding: 0 0 20px 0;
}

.login__forms {
  background: var(--primary-white);
  padding: 70px;
}

.login__main-form ul {
  padding: 0;
}

.login__inner-container {
  width: 100%;
}

::v-deep .inline-form-input-name {
  display: inline-block !important;
  width: auto !important;
  padding: 30px !important;
  border-radius: 0 !important;
  vertical-align: middle !important;
  border: solid 1px black !important;
  width: 100% !important;
}

.login__container img {
  display: none;
}

@media only screen and (max-width: 991px) {
  .login__container {
    margin: auto;
  }

  .login__layout {
    display: flex;
    align-content: flex-start;
    margin-right: 0;
    margin-left: 0;
  }

  .form-bg,
  .app-container .row div:first-child {
    padding-right: 0;
    padding-left: 0;
  }

  .login__main-form {
    margin: auto;
    width: 100%;
  }

  .login__main-form h1,
  .forgot-password-form h1,
  .reset-form h1 {
    padding: 30px 0 30px 0;
  }

  .login__forms {
    text-align: center;
    background: var(--primary-white);
    margin: auto;
    position: relative;
    padding: 35px;
    width: 95%;
  }

  .input-container {
    width: 100%;
    margin: auto;
    padding: 0;
  }

  .login__logo-container img {
    width: 250px;
    display: inline;
  }

  .login__inner-container {
    width: 90%;
    margin: auto;
  }

}
</style>
