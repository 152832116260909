import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/pages/Login';
import Stages from '@/pages/Stages';
import MemberPortal from '@/pages/MemberPortal';

// import store from './store'
Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/login',
      alias: ['/'],
      name: 'Login',
      component: Login,
      meta: {
        gtm: {
          trackPage: true,
          trackPageView: true,
          trackPageViewOnLoad: true,
          trackPageViewEventName: 'login-member-portal',
        },
      },
    },
    {
      path: '/stages',
      name: 'Stages',
      component: Stages,
      meta: {
        gtm: {
          trackPage: true,
          trackPageView: true,
          trackPageViewOnLoad: true,
          trackPageViewEventName: 'stages-member-portal',
        },
      },
    },
    {
      path: '/dashboard',
      name: 'Member Portal',
      component: MemberPortal,
    },
  ],
});

export default router;
