<template>
  <!-- update contact information modal -->
  <b-modal @hide="resetPassword" ok-only ok-title="Cancel" id="modal-3" cancel-title hide-header>
    <!-- verify user -->
    <div class="input-container">
      <!-- Choose phone or email view  -->
      <div v-show="showUpdateForm !== true">
        <div class="contact-form-title">
          <h4>What contact information would you like to update? We will send you a verification code.</h4>
        </div>
      </div>

	<!-- verification code view -->
	<div class="choose-update">
				<div>
						<span class="phone-selector selector" :class="updateType === 1 ? 'active' : ''" v-on:click="selectPhone()">Phone</span>
						<span class="email-selector selector" :class="updateType === 2 ? 'active' : ''" v-on:click="selectEmail()">Email</span>
						<div>
							<b-form v-show="showUpdateForm !== true" v-on:submit.prevent="verifyEmailPhone()" class="input-container">
								<div v-if="phone">
									<label class="sr-only" for="inline-form-new-phone">Phone Number</label>
									<b-form-input
										required
										class="update-info-input inline-form-new-phone mb-2 mr-sm-2 mb-sm-0"
										placeholder="Enter new phone number"
										type="tel"
										id="phone"
										name="phone"
										v-model="verifyPhone"
										pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
									></b-form-input>
									<b-button type="submit" variant="primary" class="btn-login-primary">Send Verification Code</b-button>
								</div>
								<div v-else>
									<label class="sr-only" for="inline-form-new-email">Email</label>
									<b-form-input
										required
										class="update-info-input inline-form-new-email mb-2 mr-sm-2 mb-sm-0"
										placeholder="Enter new email address"
										type="email"
										name="email"
										v-model="verifyEmail"
									></b-form-input>
									<b-button type="submit" variant="primary" class="btn-login-primary">Send Verification Code</b-button>
								</div>
							</b-form>
						</div>
				</div>
		</div>

      <!-- update contact information view-->
      <b-form v-show="showUpdateForm" v-on:submit.prevent="updateEmailPhone()" class="input-container">
        <div class="contact-form-title">
          <h4>Enter your updated information and verification code.</h4>
        </div>

        <!-- show new email value -->
        <div v-if="phone === null">
          <label class="sr-only" for="inline-form-new-email">New email</label>
          <b-form-input
            required
            class="update-info-input inline-form-new-email mb-2 mr-sm-2 mb-sm-0"
            placeholder="Confirm new email"
            type="email"
            name="New Email"
            v-model="updateNewEmail"
          ></b-form-input>
        </div>

        <!-- show new phone value -->
        <div v-else>
          <label class="sr-only" for="inline-form-new-phone">New phone number</label>
          <b-form-input
            required
            class="update-info-input inline-form-new-phone mb-2 mr-sm-2 mb-sm-0"
            placeholder="Confirm new phone number"
            type="tel"
            name="New Phone Number"
            v-model="updateNewPhone"
            pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
            v-on:input="resetErrorBlock()"
          ></b-form-input>
        </div>

        <!-- show code value -->
        <label class="sr-only" for="inline-form-input-code">Verification Code</label>
        <b-form-input
          required
          class="update-info-input inline-form-input-code mb-2 mr-sm-2 mb-sm-0"
          placeholder="enter verification code"
          type="number"
          name="number"
          v-model="code"
          v-on:input="resetErrorBlock()"
        ></b-form-input>
        <b-button type="submit" variant="primary" class="btn-login-primary">Update</b-button>
        <div type="button" variant="primary" class="btn-login-primary" v-on:click="resetPassword()">Reset</div>
        <MessageBlock v-if="errorInfo.trigger" v-bind:message="errorInfo.message" v-bind:status="errorInfo.status" />
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import endpoints from '../../utils/endpoints';
import MessageBlock from '../MessageBlock.vue';
import { formMessages } from '../../utils/formMessages';
import { authPost, basicGet } from '../../utils/fetchApi';
export default {
  data: function () {
    return {
      errorInfo: {
        trigger: false,
        message: '',
        status: '',
      },
      showUpdateForm: null,
      verifyPhone: null,
      verifyEmail: null,
      updatePhone: null,
      updateNewEmail: null,
      updateNewPhone: null,
      code: null,
      updateType: null,
      phone: null,
      email: null,
      config: {
        vue_app_env: this.$config.vue_app_env,
      },
    };
  },
  methods: {
    callErrorBlock(trigger, message, status) {
      this.errorInfo.trigger = trigger;
      this.errorInfo.message = message;
      this.errorInfo.status = status;
    },
    resetErrorBlock() {
      this.errorInfo.trigger = false;
      this.errorInfo.message = '';
      this.errorInfo.status = '';
    },
    logout() {
      localStorage.removeItem('u');
      localStorage.removeItem('vu');
      this.$router.push('/login');
    },
    resetPassword() {
      this.showUpdateForm = null;
      this.verifyPhone = null;
      this.email = null;
      this.phone = null;
      this.verifyEmail = null;
      this.updatePhone = null;
      this.updateNewEmail = null;
      this.updateNewPhone = null;
      this.code = null;
    },
    selectPhone() {
      this.updateType = 1;
      this.phone = true;
    },
    selectEmail() {
      this.updateType = 2;
      this.phone = null;
      this.email = true;
    },
    verifyEmailPhone() {
      const phoneNumber = this.verifyPhone ? this.verifyPhone : '';
      const email = this.verifyEmail ? this.verifyEmail : '';
      const currentEmail = this.$attrs.emailAddress;

      this.showUpdateForm = true;
      // send to phone
      if (this.updateType === 1) {
        basicGet('get', endpoints.getTextVerificationCode(this.config.vue_app_env, phoneNumber, currentEmail))
          // //FETCH FROM SERVER ENDPOINT
          .then((response) => response.text())
          .then((data) => {
            return data;
          })
          .catch((error) => {
            console.error('Error on update email/phone verification:', error);
          });
      }

      // send to email
      if (this.updateType === 2) {
        // //FETCH FROM SERVER ENDPOINT
        basicGet('get', endpoints.emailVerification(this.config.vue_app_env, email))
          .then((response) => response.text())
          .then((data) => {
            return data;
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
    },
    updateEmailPhone() {
      if ((this.updateType === 2 && this.verifyEmail === this.updateNewEmail) || (this.updateType === 1 && this.updateNewPhone === this.verifyPhone)) {
        const body = {
          updateType: this.updateType,
          emailAddress: this.$attrs.emailAddress,
          newValue: this.updateType === 1 ? this.updateNewPhone : this.updateNewEmail,
          code: parseInt(this.code),
        };

        const vu = localStorage.getItem('vu');
        // FETCH FROM SERVER ENDPOINT

        authPost('post', endpoints.updateEmailPhone(this.config.vue_app_env, body.updateType, body.emailAddress, body.newValue, body.code), vu)
          .then((response) => response.json())
          .then((data) => {
            if (data.message === 'Invalid code') {
              this.callErrorBlock(true, formMessages.updateContactInfo.invalid_code, 'error');
            }

            if (data.message === 'Email verified') {
              this.callErrorBlock(true, formMessages.updateContactInfo.success, 'success');
              setTimeout(() => {
                this.logout();
              }, 2000);
            }

            if (data.message === 'Phone number verified.') {
              this.callErrorBlock(true, formMessages.updateContactInfo.success, 'success');
              setTimeout(() => {
                this.logout();
              }, 2000);
            }
          })
          .catch((error) => {
            this.callErrorBlock(true, formMessages.updateContactInfo.error, 'error');

            console.error('Error:', error);
          });
      } else {
        this.callErrorBlock(true, formMessages.updateContactInfo.invalid_match, 'error');
      }
    },
  },
  components: {
    MessageBlock,
  },
};
</script>

<style scoped>
/* update contact info form */

.choose-update {
  display: flex;
  font-weight: bold;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.selector {
  padding: 10px;
  margin-right: 10px;
}

.selector:hover {
  cursor: pointer;
}

.update-info-input {
  margin-block: 20px !important;
  border-radius: 0;
  padding: 25px !important;

}

.contact-form-title {
  padding-bottom: 20px;
}

.active {
  background: var(--primary-green);
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 991px) {
  .referral-link {
    width: 50%;
  }
}
</style>
