export const resizeImgQualityAdvanced = (imgsrc, quality = 15, extension = null, width, height, blurred) => {
  if (imgsrc === null || imgsrc === undefined || imgsrc === '') {
    return imgsrc;
  }
  const generateBlur = () => {
    if (blurred) return '/e_blur:2000';
    return '';
  };
  const newimg = imgsrc.split('/');
  if (newimg[0] === 'http:') {
    newimg[0] = 'https:';
  }
  const indexToAdd = newimg.indexOf('upload');
  newimg.splice(indexToAdd, 1, `upload/${'q_' + String(quality)},c_fill,h_${width},w_${height}${generateBlur()}`);
  if (extension) {
    const file = newimg[newimg.length - 1].split('.');
    newimg[newimg.length - 1] = file[0] + '.' + extension;
  }
  const convertedImg = newimg.join('/');

  return String(convertedImg);
};
