const Endpoints = {
  getSignIn: (env) => {
    return env + `/api/Auth/sign-in`;
  },

  getUserById: (env, u) => {
    return env + `/api/User/get-user-by-id?userId=${u}`;
  },

  updateRewardfulObj: (env, u) => {
    return env + `/api/User/update-rewardful-info/${u}`;
  },

  getTempCode: (env) => {
    return env + `/api/Auth/get-tempcode`;
  },

  getPaymentInfo: (env) => {
    return env + `/api/Portal/get-payment-info/`;
  },

  getForgotPassword: (env, email) => {
    return env + `/api/Auth/forgot-password?emailAddress=${email}`;
  },

  getResetPassword: (env) => {
    return env + `/api/Auth/reset-password`;
  },

  getDepositStatus: (env) => {
    return env + `/api/Portal/depositstatus/`;
  },

  checkEmail: (env) => {
    return env + `/api/Portal/checkout-email`;
  },

  getBackgroundCheck: (env) => {
    return env + `/api/CarpeDM/get-background-check/`;
  },
  getPromotion: (env) => {
    return env + `/api/CarpeDM/promotion-get`;
  },
  getRewardfulUserInfo: () => {
    return `/rewardful`;
  },
  updateEmailPhone: (env, updateType, email, newValue, code) => {
    return env + `/api/Portal/update-email-phone?updateType=${updateType}&emailAddress=${email}&newValue=${newValue}&code=${code}`;
  },
  emailVerification: (env, email) => {
    return env + `/api/Auth/email-verification-code?emailAddress=${email}`;
  },
  getMembershipType: (env) => {
    return env + `/api/Portal/membershiptype-get`;
  },
  getTextVerificationCode: (env, phoneNumber, currentEmail) => {
    return env + `/api/Auth/text-verification-code?phoneNumber=${phoneNumber}&emailAdress=${currentEmail}`;
  },
  downloadLink: (env) => {
    return env + `/api/Portal/downloadlink`;
  },
};

export default Endpoints;
