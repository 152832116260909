<template>
  <b-form
    v-on:submit.prevent="forgotPassword(input_email)"
    v-if="!showResetForm"
  >
    <!-- Forgot Password -->
    <div class="input-container forgot-password-form">
      <h1>Seems like you forgot your password?</h1>
      <p>Enter your email address below to reset.</p>
      <div>
        <b-form-input
          required
          class="inline-form-input-name mb-2 mr-sm-2 mb-sm-0"
          placeholder="email"
          type="email"
          name="email"
          v-model="input_email"
        ></b-form-input>
      </div>
      <div>
        <b-button
          type="submit"
          style="cursor: pointer"
          class="btn-login-primary"
          variant="primary"
          >Send</b-button
        >
        <b-button
          style="cursor: pointer"
          class="btn-login-primary"
          v-on:click="cancelForgotPassword()"
          type="button"
          >Cancel</b-button
        >
        <a
          style="cursor: pointer; color: #3242c0; text-decoration: none"
          :href="config.linkCalendar"
          target="_blank"
          >Not a member? Create an account to get started.</a
        >
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  data() {
    return {
      input_email: '',
      config: {
        linkCalendar: this.$config.vue_app_register_url,
      },
    };
  },
  props: {
    seen: {
      type: Boolean,
    },
    forgotPassword: {
      type: Function,
    },
    showResetForm: {
      type: Boolean,
    },
    cancelForgotPassword: {
      type: Function,
    },
  },
};
</script>

<style></style>
