<template>
  <b-container fluid align-self="stretch">
    <div v-show="this.$router.currentRoute.path === '/stages'" class="stage-logout">
      <p class="logout" v-on:click="logout()">Logout</p>
    </div>
    <auto-logout v-if="this.$route.name !== 'LoginForm'"></auto-logout>
    <router-view />
  </b-container>
</template>

<script>
import './assets/styles/variables.css';
import router from './router';
import AutoLogout from './components/AutoLogout.vue';

export default {
  components: {
    AutoLogout,
  },
  name: 'App',
  data: function() {
    return {
      u: localStorage.getItem('u'),
      vu: localStorage.getItem('vu'),
    };
  },
  created: function() {},
  mounted: function() {
    console.log('Enviroment', this.$config.vue_app_enviroment_name)
    if (
      this.$store.state.loginAuth === false &&
      this.$store.state.newMemberAuth === false &&
      this.$store.state.paidMemberAuth === false &&
      this.$store.state.subscribedMemberAuth === false
    ) {
      this.$router.push('/login');
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('u');
      localStorage.removeItem('vu');
      this.$router.push('/login');
    },
  },
  computed: {
    loginAuth() {
      return this.$store.state.loginAuth;
    },
    newMemberAuth() {
      return this.$store.state.newMemberAuth;
    },
    paidMemberAuth() {
      return this.$store.state.paidMemberAuth;
    },
    subscribedMemberAuth() {
      return this.$store.state.subscribedMemberAuth;
    },
    depositSuccess() {
      return this.$store.state.depositSuccess;
    },
    membershipSuccess() {
      return this.$store.state.membershipSuccess;
    },
  },
  watch: {
    deep: true,
    loginAuth(value) {
      if (value) {
        router.push('./dashboard');
      }
    },
    newMemberAuth(value) {
      if (value) {
        // re-route to stages or deposit
        router.push('./stages');
      }
    },
    paidMemberAuth(value) {
      if (value) {
        // re-route to stages or deposit
        router.push('./stages');
      }
    },
    subscribedMemberAuth(value) {
      if (value) {
        // re-route to stages or deposit
        router.push('./stages');
      }
    },
    depositSuccess(value) {
      if (value) {
        router.push('./stages');
      }
    },
    membershipSuccess(value) {
      if (value) {
        router.push('./stages');
      }
    },
  },
};
</script>

<style>
body {
  animation: phaseIn 1s 1 ease-in-out;
}

.referral-btn {
  background: var(--primary-green) !important;
  border: none !important;
  border-radius: 0 !important;
  width: 120px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 99;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 !important;
  border-radius: 0 !important;
  outline: 0;
  padding: 140px !important;
}

@media only screen and (max-width: 575px) {
  .modal-content {
    padding: 40px !important;
  }
}

.modal-content .btn-primary {
  color: #fff;
  background-color: var(--primary-green) !important;
  border-color: var(--primary-green) !important;
  border-radius: 0;
}

.modal.show .modal-dialog {
  transform: none !important;
  min-height: calc(100vh - 60px) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  overflow: auto !important;
  max-width: 700px;
  text-align: center;
}

/* hooper */

.hooper {
  height: 800px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.hooper-track {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* height: 100%; */
}

.hooper-slide {
  width: 400px;
  height: 200px !important;
  flex-wrap: nowrap;
  overflow: hidden;
  margin: auto;
  border-bottom: 10px solid #ececec;
}

.hooper.is-vertical .hooper-track {
  flex-direction: row;
}

.hooper-next {
  top: auto;
  bottom: -50px !important;
  left: 0;
  transform: initial;
}

.hooper-prev {
  top: -50px !important;
  bottom: auto;
  left: 0;
  transform: initial;
}

.hooper-next,
.hooper-prev {
  width: 100% !important;
}

.stage-logout {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  padding: 20px 70px 0 20px;
  cursor: pointer;
}

.stage-logout p {
  color: var(--primary-green);
}

@media only screen and (max-width: 991px) {
  .hooper {
    width: 60%;
    height: 800px !important;
  }

  .hooper-navigation.is-vertical .hooper-next {
    bottom: 0;
  }

  .hooper-slide {
    width: 400px;
    flex-wrap: nowrap;
    margin: auto;
    height: 200px !important;
  }
}
</style>
