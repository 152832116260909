import Vuex from "vuex";
import Vue from "vue";
import Authentication from "../store/auth";

// load vue
Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
	namespaced: true,
	modules: {
		Authentication,
	},
});
