export const logout = () => {
	localStorage.removeItem("u");
	localStorage.removeItem("vu");
};

export function WriteCookie() {
	let cookieInfo = {
		value: "private",
		domain: "carpedmdating.com",
	};

	this.getCookieInfo = function() {
		return (document.cookie = `myValue=${cookieInfo.value};path=/;domain=${cookieInfo.domain}`);
	};

	Object.defineProperty(this, "cookieInfo", {
		get: function() {
			return cookieInfo;
		},
		set: function(value) {
			if (!value.value || !value.domain) {
				throw new Error("invalid location");
			}
			cookieInfo = value;
		},
	});
}
