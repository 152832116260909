<template>
	<LoginForm />
</template>

<script>
	import LoginForm from "../components/login/LoginForm.vue";

	export default {
		components: {
			LoginForm,
		},
	};
</script>

<style></style>
