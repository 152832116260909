<template>
  <Fragment>
    <b-modal
      ref="bg-check-in-progress-modal"
      id="bg-check-in-progress-modal"
      :clickToClose="false"
      :no-close-on-backdrop="true"
      hide-footer
      hide-header
    >
      <p>
        Background Check In Progress. We'll notify you via email when your
        results are in.
      </p>
      <a :href="config.linkCalendar" target="_blank"
        >Return to My Application</a
      >
    </b-modal>
    <Deposit v-if="newMemberAuth" />
    <DepositSuccess v-else-if="depositSuccess" />
    <Membership v-bind:group="group" v-else-if="subscribedMemberAuth" />
    <MembershipSuccess v-else-if="membershipSuccess" />
  </Fragment>
</template>

<script>
import endpoints from '../utils/endpoints';
import { authGet } from '../utils/fetchApi';
import Deposit from '../components/deposit-payment/Deposit.vue';
import DepositSuccess from '../components/deposit-payment/DepositSuccess.vue';
import Membership from '../components/subscription-payment/Membership.vue';
import MembershipSuccess from '../components/subscription-payment/MembershipSuccess.vue';
import {
  depositSuccessMarker,
  newMember,
  bgCheckPass,
  memberSuccessMarker,
} from '../utils/userStatus';

export default {
  data() {
    return {
      token: localStorage.getItem('vu'),
      userId: localStorage.getItem('u'),
      group: null,
      config: {
        vue_app_env: this.$config.vue_app_env,
        linkCalendar: this.$config.vue_app_register_url,
      },
      screenings: {
        criminalCheck: '',
        sexOffenderCheck: '',
      },
    };
  },
  mounted: function () {
    this.checkDepositIsPaid(this.token, this.userId);
  },
  computed: {
    loginAuth() {
      return this.$store.state.loginAuth;
    },
    newMemberAuth() {
      return this.$store.state.newMemberAuth;
    },
    paidMemberAuth() {
      return this.$store.state.paidMemberAuth;
    },
    subscribedMemberAuth() {
      return this.$store.state.subscribedMemberAuth;
    },
    depositSuccess() {
      return this.$store.state.depositSuccess;
    },
    membershipSuccess() {
      return this.$store.state.membershipSuccess;
    },
  },
  methods: {
    showModal() {
      if (this.$refs['bg-check-in-progress-modal']) this.$refs['bg-check-in-progress-modal'].show();
    },
    logout() {
      localStorage.removeItem('u');
      localStorage.removeItem('vu');
      this.$router.push('/login');
    },
    checkDepositIsPaid(vu, u) {
      // Check to see if deposit is paid in every stage

      authGet('get', endpoints.getDepositStatus(this.config.vue_app_env), vu)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.getProfileStatus(vu, u);
            this.getUserMembershipGroup(vu);
          } else {
            // if not paid set new member marker
            newMember();
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    checkScreenings(data) {
      const screenings = data.screenings;
      screenings.map((obj) => {
        if (obj.name === 'National Criminal Search' && obj.status === 'Clear') {
          this.screenings.criminalCheck = 'Clear';
          return this.screenings.criminalCheck;
        } else if (obj.name === 'Sex Offender Search' && obj.status === 'Clear') {
          this.screenings.sexOffenderCheck = 'Clear';
          return this.screenings.sexOffenderCheck;
        }
        return null;
      });
    },
    // checkBackgroundCheck(vu) {
    // 	// Check if background check is "Complete"
    // 	authGet("get", endpoints.getBackgroundCheck(this.config.vue_app_env), vu)
    // 		.then((response) => response.json())
    // 		.then((data) => {
    // 			if (data) {
    // 				if (data.status === "Complete") {
    // 					//background check completed
    // 					this.checkIfUserIsCustomer(vu);
    // 					console.log("screening obj", data);
    // 				} else if (data.status === "CaseCreated") {
    // 					//essential screenings are done
    // 					this.checkScreenings(data);
    // 					this.screenings.criminalCheck && this.sexOffenderCheck === "Clear" ? this.checkIfUserIsCustomer(vu) : depositSuccessMarker();
    // 					depositSuccessMarker();
    // 				} else {
    // 					//background check still in process
    // 					depositSuccessMarker();
    // 				}
    // 			}
    // 		})
    // 		.catch((error) => {
    // 			depositSuccessMarker();
    // 			console.log("Error:", error);
    // 		});
    // },
    getProfileStatus(vu, u) {
      authGet('get', endpoints.getUserById(this.config.vue_app_env, u), vu)
        .then((response) => response.json())
        .then((data) => {
          const profileStatusKey = data.profileStatus.key;
          // check profile status
          if (profileStatusKey === 'BCKINIT' || profileStatusKey === 'INTERVIEWAPPROVED') {
            this.showModal();
            depositSuccessMarker();
          } else if (
            profileStatusKey === 'BCKCOMPLETED' ||
            profileStatusKey === 'PAID'
          ) {
            this.checkIfUserIsCustomer(vu);
          } else if (profileStatusKey === 'BCKREJECTED') {
            this.logout();
          } else {
            depositSuccessMarker();
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    checkIfUserIsCustomer(vu) {
      // Get user payment info to check the background check status.

      authGet('get', endpoints.getPaymentInfo(this.config.vue_app_env), vu)
        .then((response) => response.json())
        .then((data) => {
          // if customer id is present - go to the membership success page
          if (
            data.membership.customerId !== 'string' &&
            data.membership.customerId !== null
          ) {
            // FIXME: this is a hack to get around the fact that the API is returning a string instead of a null value
            // if membership status is paid - user is a paid member
            // send user directly to dashboard
            memberSuccessMarker();
          } else {
            // if not go to membership plan page
            bgCheckPass();
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    getUserMembershipGroup(vu) {
      // Get the users membership group
      const u = localStorage.getItem('u');

      authGet('get', endpoints.getUserById(this.config.vue_app_env, u), vu)
        .then((response) => response.json())
        .then((data) => {
          const group = data.mediaGender;
          this.group = group;
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
  },
  components: {
    Deposit,
    DepositSuccess,
    Membership,
    MembershipSuccess,
  },
};
</script>

<style></style>
