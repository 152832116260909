const state = {
	loginAuth: false,
	newMemberAuth: false,
	paidMemberAuth: false,
	subscribedMemberAuth: false,
	depositSuccess: false,
	membershipSuccess: false,
	checkoutPaymentPrice: 0.0,
};

const mutations = {
	isLoginAuth(state, payload) {
		state.loginAuth = payload;
		// access to dashboard
	},

	isNewMemberAuth(state, payload) {
		state.newMemberAuth = payload;
		// access to deposit
	},
	isPaidMemberAuth(state, payload) {
		state.paidMemberAuth = payload;
		// background check approval wait
	},
	isSubscribedMemberAuth(state, payload) {
		state.subscribedMemberAuth = payload;
		// access to membership
	},
	isDepositSuccess(state, payload) {
		state.depositSuccess = payload;
		// marker that member has paid for deposit
	},
	isMembershipSuccess(state, payload) {
		state.membershipSuccess = payload;
		// marker that member has paid for subscription
	},
	setPaymentPrice(state, payload) {
		state.checkoutPaymentPrice = payload;
	},
};

const actions = {
	setPaymentPrice: ({ commit }, payload) => {
		commit("setPaymentPrice", payload);
	},
};

const getters = {
	getPaymentPrice: (state) => state,
};

export default {
	state,
	actions,
	getters,
	mutations,
};
