<template>
	<Dashboard />
</template>

<script>
	import Dashboard from "../components/dashboard/Dashboard.vue";

	export default {
		name: "MemberPortal",
		mounted: () => {},
		components: {
			Dashboard,
		},
		methods: {},
	};
</script>

<style></style>
