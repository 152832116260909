<template>
  <div class="success-container">
    <div
      class="success-message"
      style="display: flex; flex-direction: column; text-align: center"
    >
      <h1 style="color: #3242c0">Your deposit was successfully paid!</h1>
      <p>
        Now it's time to meet your matchmaker. Select a convenient time from our
        calendar.
      </p>
      <form id="success-form">
        <a :href="config.linkCalendar" target="_blank"
          ><div class="getAppBtn d-block">Go to Calendar</div>
        </a>
        <br />
        <br />
        <p style="color: #15bda5; display: inline" v-on:click="logOut()">
          Return to Log-in
        </p>
      </form>
    </div>
  </div>
</template>

<script>
	import endpoints from "../../utils/endpoints";
	import { authGet } from "../../utils/fetchApi";
	export default {
		data: function() {
			return {
				membership: null,
				config: {
					vue_app_env: this.$config.vue_app_env,
          linkCalendar: this.$config.vue_app_register_url,
				},
			};
		},
		mounted: function() {
			this.getDepositAmount();
		},
		methods: {
			logOut() {
				localStorage.removeItem("u");
				localStorage.removeItem("vu");
				this.$router.push("/login");
			},
			getDepositAmount() {
				// Call method on component mount if user tries to come back to login
				const vu = localStorage.getItem("vu");
				authGet("get", endpoints.getPaymentInfo(this.config.vue_app_env), vu)
					.then((response) => response.json())
					.then((data) => {
						return data;
					})
					.catch((error) => {
						console.log("Error:", error);
					});
			},
		},
	};
</script>

<style scoped>
.success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#success-form {
  margin-top: 30px;
  margin: auto;
}

#success-form p {
  cursor: pointer;
}

.getAppBtn {
  cursor: pointer;
  width: 100%;
  margin: auto;
  margin: 20px 0 0 0;
  padding: 10px 20px;
  background: #15bda5;
  color: white;
  width: 150px;
}

#success-form a:hover {
  text-decoration: none !important;
}
</style>
