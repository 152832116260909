export const formMessages = {
	errors: {
		login: {
			invalid_name_password: "Invalid username and/or password.",
			invalid_passcode: "Incorrect passcode. Please try again.",
			invalid_member: "Not a member",
			invalid_new_password: {
				message:
					"New password or passcode is invalid. Password requires the following: At least 8 characters. At least 1 uppercase letter. At least 1 number. At least 1 symbol.",
				rules: ["At least 8 characters.", "At least 1 uppercase letter.", "At least 1 number.", "At least 1 symbol."],
			},
			invalid_fields: "Please enter all fields.",
			invalid_matched_password: "Password doesn't match.",
			invalid_credentials: "Invalid credentials. Please try again.",
		},
	},
	success: {
		login: {
			password_created: "New password has been created!",
		},
	},
	updateContactInfo: {
		success: "Information has been updated!",
		error: "Verification is invalid, please try again.",
		invalid_match: "Invalid match, please try again",
		invalid_code: "Invalid code",
	},
	cashOut: {
		success: "Cash Out Notification Sent!",
		error: "Commission Not Found.",
	},
};
