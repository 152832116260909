import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import { BootstrapVue } from 'bootstrap-vue';
import App from '@/App.vue';
import router from './router';
import Fragment, { Plugin } from 'vue-fragment';
import store from './store/auth';
import { config } from './constants';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(Plugin);
Vue.use(Fragment.Plugin);
Vue.use(Fragment);

Vue.config.productionTip = false;

// pass env variables to all components
Vue.prototype.$config = config;

// vue dev tools
Vue.config.devtools = config.dev_tools;

// g tag manager
if (config.vue_app_enviroment_name === 'production') {
  Vue.use(VueGtm, {
    id: 'GTM-WZJF346',
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    ignoredViews: ['dashboard'],
    trackOnNextTick: false,
  });
}

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
