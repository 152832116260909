// POST
export const basicPost = (method, url, body) => {
	return fetch(url, {
		method,
		headers: {
			Accept: "application/json, text/plain",
			"Content-Type": "application/json;charset=UTF-8",
		},
		body: JSON.stringify(body),
	});
};

// POST w/ Authorization Token
export const authPost = (method, url, token, body) => {
	return fetch(url, {
		method,
		headers: {
			Accept: "application/json, text/plain",
			"Content-Type": "application/json;charset=UTF-8",
			Authorization: "Bearer " + token,
		},
		body: JSON.stringify(body),
	});
};

// GET
export const basicGet = (method, url) => {
	return fetch(url, {
		method,
		headers: {
			Accept: "application/json, text/plain",
			"Content-Type": "application/json;charset=UTF-8",
		},
	});
};

// GET w/ Authorization Token
export const authGet = (method, url, token) => {
	return fetch(url, {
		method,
		headers: {
			Accept: "application/json, text/plain",
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json;charset=UTF-8",
			Authorization: "Bearer " + token,
		},
	});
};
