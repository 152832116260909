<template>
	<div class="subscription-card" md="12">
		<div :class="flagClass" v-bind:style="{ color: white_text, background: flag_color }">{{ flag }}</div>
		<div class="card-info">
			<div class="card-title">
				<h1>{{ name }}</h1>
			</div>
			<div class="card-price-container">
				<span :class="salePrice ? 'sale' : ''" style="font-size: 25px; color:red;text-decoration:line-through 4px;font-family: Arial">
					<span style="color:black">
						${{ price }}
					</span>
				</span>
				<span style="
					font-size: 25px;
				">/{{ duration }}</span>
			</div>
			<h2 v-if="salePrice !== '' || null" style="color: black; padding-top: 25px;" class="card-sale">${{ salePrice }}</h2>
			<ul class="card-details">
				{{
					details
				}}
			</ul>
			<b-button class="bottom-caption premium-year" v-on:click="checkout(priceId, price, salePrice)">Choose Plan</b-button>
		</div>
	</div>
</template>

<script>
	import endpoints from "../../utils/endpoints";
	import { authGet } from "../../utils/fetchApi";
	import store from "../../store/index";
	import { mapActions } from "vuex";
	import { removeSpaces } from "../../utils/removeSpaces";
	let stripe;
	process.env.NODE_ENV === "production"
		? (stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_PUBLISH_KEY}`))
		: (stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_PUBLISH_KEY_TEST}`));

	export default {
		props: {
			name: {
				type: String,
			},
			price: {
				type: String,
			},
			duration: {
				type: String,
			},
			flag: {
				type: String,
			},
			sale: {
				type: Boolean,
			},
			salePrice: {
				type: String,
			},
			flag_color: {
				type: String,
			},
			flagClass: {
				type: String,
			},
			white_text: {
				type: String,
			},
			priceId: {
				type: String,
			},
			details: {
				type: String,
			},
		},
		data() {
			return {
				memberInfo: {
					firstName: null,
					lastName: null,
					email: null,
					referralCode: null,
					auth: localStorage.getItem("vu"),
					userid: localStorage.getItem("u"),
				},
				checkoutType: "Subscription",
				paymentId: null,
				referralId: null,
				config: {
					vue_app_env: this.$config.vue_app_env,
					vue_app_host: this.$config.vue_app_host,
				},
			};
		},
		mounted: function() {
			this.getUserDataForCheckout();
			this.getPaymentIdForCheckout();
			this.checkForUserReferralId();
			console.log("paymentId", this.$attrs.paymentId ? "paymentId recieved" : "paymentId not recieved");
		},
		methods: {
			checkForUserReferralId() {
				const u = localStorage.getItem("u");
				const vu = localStorage.getItem("vu");

				authGet("get", endpoints.getUserById(this.config.vue_app_env, u), vu)
					.then((response) => response.json())
					.then((data) => {
						this.referralId = data.referralId;
					})
					.catch((error) => {
						console.log("Error:", error);
					});
			},
			checkout(priceId, price, salePrice) {
				// set price payment for fb pixel
				store.dispatch("setPaymentPrice", salePrice !== "" || null ? parseInt(salePrice) : parseInt(price));

				const body = {
					firstName: this.memberInfo.firstName,
					lastName: this.memberInfo.lastName,
					email: this.memberInfo.email,
					auth: this.memberInfo.auth,
					userid: this.memberInfo.userid,
					priceId,
					subscriptionName: removeSpaces(this.$props.name),
					memberType: this.$props.duration,
					referral: this.referralId,
					referralCode: this.memberInfo.referralCode,
					checkoutType: this.checkoutType,
					paymentId: this.paymentId,
				};
				const subscribeCheckoutSession = `${this.config.vue_app_host}/api/subscribe-checkout-session`;

				const createCheckoutSession = function() {
					return fetch(subscribeCheckoutSession, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(body),
					}).then(function(result) {
						return result.json();
					});
				};

				const handleResult = function() {
					console.log("error");
				};

				createCheckoutSession(priceId).then(function(data) {
					// Call Stripe.js method to redirect to the new Checkout page
					stripe
						.redirectToCheckout({
							sessionId: data.sessionId,
						})
						.then(handleResult);
				});
			},
			getUserDataForCheckout() {
				const u = localStorage.getItem("u");
				const vu = localStorage.getItem("vu");

				authGet("get", endpoints.getUserById(this.config.vue_app_env, u), vu)
					.then((response) => response.json())
					.then((data) => {
						this.memberInfo.firstName = data.firstName;
						this.memberInfo.lastName = data.lastName;
						this.memberInfo.email = data.emailAddress;
						this.memberInfo.referralCode = data.referralCode;
					})
					.catch((error) => {
						console.log("Error:", error);
					});
			},
			getPaymentIdForCheckout() {
				const vu = localStorage.getItem("vu");

				authGet("get", endpoints.getPaymentInfo(this.config.vue_app_env), vu)
					.then((response) => response.json())
					.then((data) => {
						const paymentId = data.membership.payments[0].id;
						this.paymentId = paymentId;
						console.log("paymentid", paymentId);
					})
					.catch((error) => {
						console.log("Error:", error);
					});
			},
			...mapActions(["setPaymentPrice"]),
		},
	};
</script>

<style scoped>
	.subscription-card {
		height: 100%;
	}

	.upper-caption {
		background: var(--primary-yellow);
		color: rgb(15, 14, 14);
		position: absolute;
		width: 200px;
		padding: 5px;
		top: -20px;
		text-align: center;
		font-weight: bold;
	}

	.bottom-caption {
		background: var(--primary-green);
		color: white;
		position: absolute;
		border-radius: 0;
		width: 50%;
		left: 25%;
		padding: 5px;
		bottom: -20px;
		text-align: center;
	}

	.bottom-caption:hover {
		background-color: var(--primary-green);
		color: white;
		position: absolute;
		border-radius: 0;
		padding: 5px;
		bottom: -20px;
		text-align: center;
	}

	.card-price-container {
		text-align: center;
		border-bottom: 1px solid black;
		position: relative;
	}

	.card-price-container h2 {
		font-weight: bold;
	}

	.card-sale {
		font-weight: bold;
		text-align: center;
	}

	.card-price-container h2 span,
	.card-sale span {
		font-size: 0.5em;
		font-weight: normal;
		position: relative;
		padding-left: 10px;
	}

	.card-info {
		padding: 30px;
		height: 100%;
	}

	.card-title {
		color: var(--primary-blue);
		border-bottom: 1px solid black;
	}

	.card-details {
		padding: 0;
		padding-top: 20px;
	}

	.card-details li {
		list-style: none;
		text-align: center;
		padding-bottom: 10px;
	}

	.card-sale {
		font-size: 2.5rem;
	}

	.card-title h1 {
		text-align: center;
	}

	@media only screen and (max-width: 991px) {

		.card-sale {
			font-size: 2rem !important;
		}

		.bottom-caption {
			background: var(--primary-green);
			color: var(--primary-white);
			position: relative;
			border-radius: 0;
			width: 100%;
			left: auto;
			padding: 5px;
			bottom: 0;
			text-align: center;
		}
	}

</style>
