<template>
	<b-form v-on:submit.prevent="getTempCodeAuthorization(input_passcode)" class="input-container" id="tempcode-form">
		<div class="inner-container">
			<div>
				<h1>Verification Code</h1>
				<p>We sent a verification code via SMS to the phone number linked to your account. Enter the code below to login.</p>
				<b-form-input
					required
					id="tempCode"
					class="inline-form-input-name mb-2 mr-sm-2 mb-sm-0"
					placeholder="enter code"
					type="number"
					min="99999"
					max="999999"
					name="tempcode"
					v-model="input_passcode"
					v-on:input="resetErrorBlock()"
				></b-form-input>
			</div>
			<div>
				<b-button type="submit" class="btn-login-primary" variant="primary">Sign In</b-button>
				<b-button
					type="submit"
					style="cursor:pointer;color:#3242c0;padding:0 0 16px 0;text-decoration:none"
					variant="link"
					v-on:click="resetTempCode()"
					>Resend Code. Text may take a few seconds.</b-button
				>
				<p style="cursor:pointer;color:#3242c0" v-on:click="reloadPage()">Cancel</p>
			</div>
		</div>
	</b-form>
</template>

<script>
	export default {
		data() {
			return {
				input_passcode: this.inputPasscode,
			};
		},
		props: {
			inputPasscode: {
				type: String,
			},
			resetTempCode: {
				type: Function,
			},
			getTempCodeAuthorization: {
				type: Function,
			},
			reloadPage: {
				type: Function,
			},
			resetErrorBlock: {
				type: Function,
			},
		},
	};
</script>

<style></style>
