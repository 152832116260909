// constants for frontend
const prod = {
  dev_tools: false,
  vue_app_enviroment_name: process.env.NODE_ENV,
  vue_app_env: process.env.VUE_APP_ENV,
  vue_app_stripe_publish_key: process.env.VUE_APP_STRIPE_PUBLISH_KEY,
  vue_app_public_host: process.env.VUE_APP_HOST,
  vue_app_host: process.env.VUE_APP_HOST,
  vue_app_register_url: process.env.VUE_APP_ENV_REGISTER,
  product_ids: {
    vue_app_deposit_price_id: process.env.VUE_APP_DEPOSIT_PRICE_ID,
    vue_app_premium_annual_price_id: process.env.VUE_APP_PREMIUM_ANNUAL_PRICE_ID,
    vue_app_premium_month_price_id: process.env.VUE_APP_PREMIUM_MONTH_PRICE_ID,
    vue_app_gallery_annual_price_id: process.env.VUE_APP_GALLERY_ANNUAL_PRICE_ID,
    vue_app_gallery_month_price_id: process.env.VUE_APP_GALLERY_MONTH_PRICE_ID,
    vue_app_pool_annual_price_id: process.env.VUE_APP_POOL_ANNUAL_PRICE_ID,
    vue_app_pool_month_price_id: process.env.VUE_APP_POOL_MONTH_PRICE_ID,
  },
};

const dev = {
  dev_tools: true,
  vue_app_enviroment_name: process.env.NODE_ENV,
  vue_app_env: process.env.VUE_APP_ENV_TEST,
  vue_app_stripe_publish_key: process.env.VUE_APP_STRIPE_PUBLISH_KEY_TEST,
  vue_app_public_host: process.env.VUE_APP_HOST_TEST,
  vue_app_host: process.env.VUE_APP_HOST_TEST,
  vue_app_register_url: process.env.VUE_APP_ENV_REGISTER_TEST,
  product_ids: {
    vue_app_deposit_price_id: process.env.VUE_APP_DEPOSIT_PRICE_ID_TEST,
    vue_app_premium_annual_price_id: process.env.VUE_APP_PREMIUM_ANNUAL_PRICE_ID_TEST,
    vue_app_premium_month_price_id: process.env.VUE_APP_PREMIUM_MONTH_PRICE_ID_TEST,
    vue_app_gallery_annual_price_id: process.env.VUE_APP_GALLERY_ANNUAL_PRICE_ID_TEST,
    vue_app_gallery_month_price_id: process.env.VUE_APP_GALLERY_MONTH_PRICE_ID_TEST,
    vue_app_pool_annual_price_id: process.env.VUE_APP_POOL_ANNUAL_PRICE_ID_TEST,
    vue_app_pool_month_price_id: process.env.VUE_APP_POOL_MONTH_PRICE_ID_TEST,
  },
};

const uat = {
  dev_tools: true,
  vue_app_env: process.env.VUE_APP_ENV_UAT,
  vue_app_enviroment_name: process.env.NODE_ENV,
  vue_app_stripe_publish_key: process.env.VUE_APP_STRIPE_PUBLISH_KEY_TEST,
  vue_app_public_host: process.env.VUE_APP_HOST_UAT,
  vue_app_host: process.env.VUE_APP_HOST_UAT,
  vue_app_register_url: process.env.VUE_APP_ENV_REGISTER_UAT,
  product_ids: {
    vue_app_deposit_price_id: process.env.VUE_APP_DEPOSIT_PRICE_ID_TEST,
    vue_app_premium_annual_price_id: process.env.VUE_APP_PREMIUM_ANNUAL_PRICE_ID_TEST,
    vue_app_premium_month_price_id: process.env.VUE_APP_PREMIUM_MONTH_PRICE_ID_TEST,
    vue_app_gallery_annual_price_id: process.env.VUE_APP_GALLERY_ANNUAL_PRICE_ID_TEST,
    vue_app_gallery_month_price_id: process.env.VUE_APP_GALLERY_MONTH_PRICE_ID_TEST,
    vue_app_pool_annual_price_id: process.env.VUE_APP_POOL_ANNUAL_PRICE_ID_TEST,
    vue_app_pool_month_price_id: process.env.VUE_APP_POOL_MONTH_PRICE_ID_TEST,
  },
};

const int = {
  dev_tools: true,
  vue_app_env: process.env.VUE_APP_ENV_INT,
  vue_app_enviroment_name: process.env.NODE_ENV,
  vue_app_stripe_publish_key: process.env.VUE_APP_STRIPE_PUBLISH_KEY_TEST,
  vue_app_public_host: process.env.VUE_APP_HOST_INT,
  vue_app_host: process.env.VUE_APP_HOST_INT,
  vue_app_register_url: process.env.VUE_APP_ENV_REGISTER_INT,
  product_ids: {
    vue_app_deposit_price_id: process.env.VUE_APP_DEPOSIT_PRICE_ID_TEST,
    vue_app_premium_annual_price_id: process.env.VUE_APP_PREMIUM_ANNUAL_PRICE_ID_TEST,
    vue_app_premium_month_price_id: process.env.VUE_APP_PREMIUM_MONTH_PRICE_ID_TEST,
    vue_app_gallery_annual_price_id: process.env.VUE_APP_GALLERY_ANNUAL_PRICE_ID_TEST,
    vue_app_gallery_month_price_id: process.env.VUE_APP_GALLERY_MONTH_PRICE_ID_TEST,
    vue_app_pool_annual_price_id: process.env.VUE_APP_POOL_ANNUAL_PRICE_ID_TEST,
    vue_app_pool_month_price_id: process.env.VUE_APP_POOL_MONTH_PRICE_ID_TEST,
  },
};

const envConfig = {
  production: prod,
  uat,
  integration: int,
  development: dev,
  default: dev,
};

export const config = envConfig[process.env.NODE_ENV] || envConfig.default;
