<template>
	<div class="error-block">
		<!-- error block -->
		<div v-show="status === 'success'" class="success">{{ message }}</div>
		<!-- success block -->
		<div v-show="status === 'error'" class="err">{{ message }}</div>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {};
		},
		props: {
			message: {
				type: String,
			},
			status: {
				type: String,
			},
		},
	};
</script>

<style>
	.error-block ul {
		padding-left: 0;
	}

	.err {
		color: var(--error-red);
		list-style: none;
		animation: phaseIn 1s 1 ease-in-out;
	}

	.success {
		color: var(--primary-green);
		list-style: none;
		animation: phaseIn 1s 1 ease-in-out;
	}
</style>
