<template>
	<div>
		<b-row>
			<b-col lg="5" md="4" cols="2"></b-col>
			<b-col lg="2" md="4" cols="8">
				<img class="logo" style="width: 100%" src="@/assets/carpedm-color-logo.png" />
			</b-col>
			<b-col lg="5" md="4" cols="2"></b-col>
		</b-row>
		<b-row>
			<b-col lg="3"></b-col>
			<b-col lg="6" cols="12" class="subscription-header">
				<h1>Membership Plans</h1>
				<p>
					Congratulations on passing your background check! You’re one step away from being a member of the CarpeDM community, getting full app
					access, dating the highest quality singles, and accessing exclusive member-only content.
					<br />
					<br />
					We’re giving our first 500 members access at our introductory rates. Prices will go up, so join now by selecting your membership plan below.
				</p>
			</b-col>
			<b-col lg="3"></b-col>
		</b-row>
		<b-row>
			<b-col cols="4"></b-col>
			<b-col lg="4" cols="12">
				<div class="subscription-selector" v-show="group1 === 'active'">
					<b-button :class="{ active: yearly, '': !yearly }" v-on:click="yearly = true" id="yearly-selector">Annual</b-button>
					<b-button :class="{ active: !yearly, '': yearly }" v-on:click="yearly = !true" id="half-selector">6 Month</b-button>
				</div>
				<div class="subscription-selector" v-show="group2 === 'active'">
					<b-button :class="{ active: yearlyPool, '': !yearlyPool }" v-on:click="yearlyPool = true" id="yearly-selector">Annual</b-button>
					<b-button :class="{ active: !yearlyPool, '': yearlyPool }" v-on:click="yearlyPool = !true" id="half-selector">6 Month</b-button>
				</div>
			</b-col>
			<b-col cols="4"></b-col>
		</b-row>

		<!-- group 1 members Female -->
		<b-row id="section" class="group1 yearly" v-if="yearly" v-show="group1 === 'active'">
			<b-col d-none md="2" lg="3"></b-col>
			<b-col class="card-container" cols="12" md="4" lg="3">
				<!-- premium_annual -->
				<Card
					:name="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.PREMIUM_ANNUAL).title : ''"
					:price="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.PREMIUM_ANNUAL).price : ''"
					:details="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.PREMIUM_ANNUAL).description : ''"
					:duration="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.PREMIUM_ANNUAL).type : ''"
					:flag="flags.best_val"
					:flag_color="flags.best_val_color"
					:flagClass="flags.class"
					:priceId="membershipProductsId.PREMIUM_ANNUAL"
					:salePrice="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.PREMIUM_ANNUAL).actualCost : ''"
					:sale="flags.sale"
				/>
			</b-col>
			<b-col class="card-container" cols="12" md="4" lg="3">
				<!-- gallery_annual -->
				<Card
					:name="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.GALLERY_ANNUAL).title : ''"
					:price="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.GALLERY_ANNUAL).price : ''"
					:details="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.GALLERY_ANNUAL).description : ''"
					:duration="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.GALLERY_ANNUAL).type : ''"
					:flag="flags.favorite"
					:white_text="flags.alt_color"
					:flag_color="flags.favorite_color"
					:flagClass="flags.class"
					:priceId="membershipProductsId.GALLERY_ANNUAL"
					:salePrice="femaleMemberships ? femaleMemberships.annual.find(m => m.title === namesMemberships.GALLERY_ANNUAL).actualCost : ''"
					:sale="flags.sale"
				/>
			</b-col>
			<b-col d-none md="2" lg="3"></b-col>
		</b-row>

		<b-row id="section" class="group1 half" v-if="!yearly">
			<b-col d-none md="2" lg="3"></b-col>
			<b-col class="card-container" cols="12" md="4" lg="3">
				<!-- premium_month -->
				<Card
					v-bind:name="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.PREMIUM_SIX).title : ''"
					v-bind:price="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.PREMIUM_SIX).price : ''"
					v-bind:details="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.PREMIUM_SIX).description : ''"
					v-bind:duration="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.PREMIUM_SIX).type : ''"
					v-bind:priceId="membershipProductsId.PREMIUM_SIX"
					v-bind:salePrice="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.PREMIUM_SIX).actualCost : ''"
					v-bind:sale="flags.sale"
				/>
			</b-col>
			<b-col class="card-container" cols="12" md="4" lg="3">
				<!-- gallery_month -->
				<Card
					v-bind:name="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.GALLERY_SIX).title : ''"
					v-bind:price="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.GALLERY_SIX).price : ''"
					v-bind:details="memberships[3].description"
					v-bind:duration="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.GALLERY_SIX).type : ''"
					v-bind:priceId="membershipProductsId.GALLERY_SIX"
					v-bind:salePrice="femaleMemberships ? femaleMemberships.sixMonth.find(m => m.title === namesMemberships.GALLERY_SIX).actualCost : ''"
					v-bind:sale="flags.sale"
				/>
			</b-col>
			<b-col d-none md="2" lg="3"></b-col>
		</b-row>

		<!-- group 2 members Male -->
		<b-row id="section" class="group2 pool" v-if="yearlyPool" v-show="group2 === 'active'">
			<b-col class="card-container" cols="12" lg="3">
				<!-- pool_annual -->
				<Card
					v-bind:name="maleMemberships && maleMemberships.annual.find(m => m.title === namesMemberships.POOL_ANNUAL).title"
					v-bind:price="maleMemberships && maleMemberships.annual.find(m => m.title === namesMemberships.POOL_ANNUAL).price"
					v-bind:details="maleMemberships && maleMemberships.annual.find(m => m.title === namesMemberships.POOL_ANNUAL).description "
					v-bind:duration="maleMemberships && maleMemberships.annual.find(m => m.title === namesMemberships.POOL_ANNUAL).type "
					v-bind:priceId="membershipProductsId.POOL_ANNUAL"
					v-bind:salePrice="maleMemberships && maleMemberships.annual.find(m => m.title === namesMemberships.POOL_ANNUAL).actualCost "
					v-bind:sale="flags.sale"
					v-bind:flag="flags.best_val"
					v-bind:flag_color="flags.best_val_color"
					v-bind:flagClass="flags.class"
				/>
			</b-col>
		</b-row>
		<b-row id="section" class="group2 pool" v-if="!yearlyPool">
			<b-col cols="12" lg="3" class="card-container">
				<!-- pool_month -->
				<Card
					v-bind:name="maleMemberships && maleMemberships.sixMonth.find(m => m.title === namesMemberships.POOL_SIX).title"
					v-bind:price="maleMemberships && maleMemberships.sixMonth.find(m => m.title === namesMemberships.POOL_SIX).price"
					v-bind:details="maleMemberships && maleMemberships.sixMonth.find(m => m.title === namesMemberships.POOL_SIX).description"
					v-bind:duration="maleMemberships && maleMemberships.sixMonth.find(m => m.title === namesMemberships.POOL_SIX).type"
					v-bind:priceId="membershipProductsId.POOL_SIX"
					v-bind:salePrice="maleMemberships && maleMemberships.sixMonth.find(m => m.title === namesMemberships.POOL_SIX).actualCost"
					v-bind:sale="flags.sale"
					v-bind:flag="flags.favorite"
					v-bind:flag_color="flags.favorite_color"
					v-bind:flagClass="flags.class"
					v-bind:white_text="flags.alt_color"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="footer">
				<img src="@/assets/payment-methods-footer.jpg" />
				<p><a href="mailto: Membership@carpedmdating.com">Need Help?</a></p>
			</b-col>
		</b-row>
	</div>
	<!-- container -->
</template>

<script>
	import endpoints from "../../utils/endpoints";
	import { authGet } from "../../utils/fetchApi";
	import Card from "./MembershipCard";

	export default {
		components: {
			Card,
		},
		data: function() {
			// names of memberships
			return {
				namesMemberships: {
					PREMIUM_ANNUAL: "Premium Annual",
					PREMIUM_SIX: "Premium Six",
					GALLERY_SIX: "Gallery Six",
					GALLERY_ANNUAL: "Gallery Annual",
					POOL_SIX: "Pool Six",
					POOL_ANNUAL: "Pool Annual",
				},
				// memberships stripe id
				membershipProductsId: {
					PREMIUM_ANNUAL: this.$config.product_ids.vue_app_premium_annual_price_id,
					PREMIUM_SIX: this.$config.product_ids.vue_app_premium_month_price_id,
					GALLERY_SIX: this.$config.product_ids.vue_app_gallery_month_price_id,
					GALLERY_ANNUAL: this.$config.product_ids.vue_app_gallery_annual_price_id,
					POOL_SIX: this.$config.product_ids.vue_app_pool_month_price_id,
					POOL_ANNUAL: this.$config.product_ids.vue_app_pool_annual_price_id,
				},
				memberships: null,
				maleMemberships: null,
				femaleMemberships: null,
				yearly: true,
				yearlyPool: true,
				half: null,
				group1: null,
				group2: null,
				flags: {
					sale: null,
					favorite: "Member Favorite",
					best_val: "Best Value",
					best_val_color: "#ffb600",
					favorite_color: "#AF3E52",
					alt_color: "#fff",
					class: "upper-caption",
				},
				config: {
					vue_app_env: this.$config.vue_app_env,
				},
			};
		},
		mounted: function() {
			const vu = localStorage.getItem("vu");
			this.getMemberships(vu);
			setTimeout(() => {
				this.getGroup(this.$attrs.group);
			}, 1000);
		},
		methods: {
			getGroup(group) {
				if (group === "female") {
					// is a prof black female 
					this.group1 = "active"
					this.group2 = null;
				} else if (group === "male") {
					// is a pool member
					this.group1 = false
					this.group2 = "active"
				}
			},
			getMemberships(vu) {
				// FETCH FROM SERVER ENDPOINT
				authGet("get", endpoints.getMembershipType(this.config.vue_app_env), vu)
					.then((response) => response.json())
					.then((data) => {
						this.memberships = data;
						const annualMemberships = data.filter((item) => item.type === "Annual");
						const sixMonthMemberships = data.filter((item) => item.type === "6 Month")
						// split by gender "Male" and "Female" in param gender
						this.maleMemberships = {
							annual: annualMemberships.filter((item) => item.gender === 'Male'),
							sixMonth: sixMonthMemberships.filter((item) => item.gender === 'Male'),
						}  
						this.femaleMemberships = {
							annual: annualMemberships.filter((item) => item.gender === 'Female'),
							sixMonth: sixMonthMemberships.filter((item) => item.gender === 'Female'),
						}
						console.log(this.maleMemberships, this.femaleMemberships)
					})
					.catch((error) => {
						console.error("Error:", error);
					});
			},
		},
	};
</script>

<style scoped>
	/* general */
	.active {
		color: white !important;
		background: var(--primary-blue) !important;
	}

	.subscription-selector {
		text-align: center;
	}

	#section {
		padding: 100px 0 100px 0;
	}

	#yearly-selector,
	#half-selector {
		border-radius: 0;
		color: black;
		background: lightgray;
		border: 1px solid black;
	}

	.subscription-card {
		border: solid 1px black;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.subscription-header {
		padding-bottom: 50px;
		text-align: center;
	}

	.pool {
		justify-content: center;
	}

	.footer {
		text-align: center;
		padding: 60px;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.footer img {
		width: 450px;
		margin-right: 50px;
		top: -10px;
		position: relative;
	}

	@media only screen and (max-width: 991px) {
		#section {
			padding: 50px 0 0 0;
		}

		.logo {
			padding-bottom: 20px;
		}

		.card-container {
			margin-bottom: 100px;
		}

		.subscription-selector button {
			margin-bottom: 20px;
		}

		.upper-caption {
			width: 75%;
		}

		.footer {
			padding: 0 30px 30px 30px;
		}

		.footer img {
			width: 50%;
			height: 100%;
		}
	}
</style>
