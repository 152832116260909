<template>
	<div id="loader-screen">
		<svg id="loader-icon" width="75" height="75" viewBox="0 0 360 360" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M45.6 180C45.6 192.371 35.569 202.398 23.198 202.398C10.827 202.398 0.800003 192.371 0.800003 180C0.800003 167.629 10.827 157.602 23.198 157.602C35.569 157.602 45.6 167.629 45.6 180"
				fill="white"
			/>
			<path
				d="M56.78 82.22C67.495 88.4075 71.167 102.107 64.9792 112.822C58.7956 123.533 45.0962 127.205 34.3812 121.021C23.6662 114.834 19.9982 101.134 26.182 90.4192C32.3695 79.7042 46.069 76.0362 56.78 82.22"
				fill="white"
			/>
			<path
				d="M120.98 34.44C127.168 45.155 123.496 58.854 112.781 65.038C102.07 71.2255 88.3708 67.5536 82.1828 56.8388C75.9992 46.1278 79.6672 32.4288 90.382 26.2408C101.097 20.0572 114.796 23.7252 120.98 34.44"
				fill="white"
			/>
			<path
				d="M359.2 180C359.2 192.371 349.173 202.398 336.802 202.398C324.431 202.398 314.4 192.371 314.4 180C314.4 167.629 324.431 157.602 336.802 157.602C349.173 157.602 359.2 167.629 359.2 180Z"
				fill="white"
			/>
			<path
				d="M333.78 90.38C339.964 101.095 336.292 114.794 325.581 120.978C314.866 127.165 301.167 123.494 294.979 112.779C288.795 102.068 292.467 88.3688 303.178 82.1808C313.893 75.9972 327.592 79.6652 333.78 90.38"
				fill="white"
			/>
			<path
				d="M269.58 26.17C280.295 32.3575 283.963 46.057 277.779 56.772C271.592 67.483 257.892 71.155 247.177 64.9712C236.466 58.7837 232.794 45.0842 238.978 34.3692C245.165 23.6582 258.865 19.9862 269.58 26.17"
				fill="white"
			/>
			<path
				d="M325.63 239.02C336.341 245.208 340.013 258.907 333.829 269.622C327.642 280.333 313.942 284.005 303.227 277.817C292.516 271.634 288.844 257.934 295.028 247.219C301.215 236.504 314.915 232.836 325.63 239.02"
				fill="white"
			/>
			<path
				d="M277.77 303.23C283.957 313.941 280.286 327.64 269.571 333.828C258.86 340.012 245.161 336.344 238.973 325.629C232.785 314.914 236.457 301.215 247.172 295.031C257.887 288.843 271.586 292.515 277.77 303.23Z"
				fill="white"
			/>
			<path
				d="M202.4 336.8C202.4 349.171 192.373 359.198 180.002 359.198C167.631 359.198 157.604 349.171 157.604 336.8C157.604 324.429 167.631 314.398 180.002 314.398C192.373 314.398 202.4 324.429 202.4 336.8Z"
				fill="white"
			/>
			<path
				d="M202.4 23.2C202.4 35.571 192.373 45.602 180.002 45.602C167.631 45.602 157.604 35.571 157.604 23.2C157.604 10.829 167.631 0.802002 180.002 0.802002C192.373 0.802002 202.4 10.829 202.4 23.2Z"
				fill="white"
			/>
			<path
				d="M112.78 294.96C123.495 301.147 127.163 314.847 120.979 325.562C114.792 336.273 101.092 339.945 90.3772 333.761C79.6662 327.574 75.9942 313.874 82.1819 303.159C88.3655 292.448 102.065 288.776 112.78 294.96"
				fill="white"
			/>
			<path
				d="M65.04 247.18C71.2275 257.895 67.5556 271.594 56.8408 277.778C46.1298 283.966 32.4308 280.294 26.2428 269.579C20.0592 258.868 23.7272 245.169 34.442 238.981C45.1568 232.793 58.856 236.465 65.04 247.18"
				fill="white"
			/>
		</svg>
	</div>
</template>

<script>
	export default {};
</script>

<style>
	#loader-screen {
		background-color: #3242c0;
		position: absolute;
		color: white;
		font-size: 32px;
		padding-top: 10vh;
		height: 100vh;
		left: 0;
		width: 100%;
		text-align: center;
		animation: fadeIn 0.2s ease;
		z-index: 100;
	}

	#loader-icon {
		animation: spin 2s linear infinite;
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
</style>
