<template>
  <div class="outer-container">
    <UpdateContactModal v-bind:emailAddress="results.emailAddress ? results.emailAddress : ''" />
    <b-row style="padding-bottom: 30px">
      <b-col style="text-align: center" lg="3">
        <a href="https://carpedmdating.com/"><img style="width: 200px" src="@/assets/carpedm-color-logo.png" /></a>
      </b-col>
      <b-col lg="3"></b-col>
      <b-col lg="6">
        <div class="nav-menu">
          <ul>
            <li>
              <a href="https://carpedmdating.com/" target="_blank" style="text-decoration: none">Home Page</a>
            </li>
            <li style="cursor: auto">Hey, {{ results.firstName }} {{ results.lastName }}</li>
            <li style="cursor: pointer" v-on:click="logout()">Logout</li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-row class="dashboard-container">
      <!-- mobile header -->
      <div class="mobile-ultility-bar">
        <b-col id="utility-bar" cols="12" lg="3">
          <!-- welcome -->
          <div class="welcome">
            <div class="welcome-img">
              <img v-bind:src="results.profilePic" />
            </div>
            <div class="welcome-caption">
              <p>Welcome</p>
              <strong
                ><p class="username">{{ results.firstName }} {{ results.lastName }}</p></strong
              >
            </div>
          </div>

          <div id="links">
            <!-- utility links -->
            <div class="utility-links">
              <ul>
                <li>
                  <a href="https://carpedmdating.com/carpe-social/" target="_blank">Carpe Social</a>
                </li>
                <li>
                  <a href="https://carpedmdating.com/black-women-deserve/" target="_blank">Black Women Deserve</a>
                </li>
                <li>
                  <a href="https://carpedmdating.com/community-guidelines-2/" target="_blank">Community Guidelines</a>
                </li>
                <li>
                  <a href="https://carpedmdating.com/we-give-black/" target="_blank">Donation Partners</a>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
      </div>

      <!-- left bar -->
      <b-col id="utility-bar" class="desktop-ultility-bar" cols="12" lg="3">
        <!-- welcome -->
        <div class="welcome">
          <div class="welcome-img">
            <img v-bind:src="results.profilePic" />
          </div>
          <div class="welcome-caption">
            <p>Welcome</p>
            <strong
              ><p class="username">{{ results.firstName }} {{ results.lastName }}</p></strong
            >
          </div>
        </div>

        <div id="links">
          <!-- utility links -->
          <div class="utility-links">
            <ul>
              <li>
                <a href="https://carpedmdating.com/carpe-social/" target="_blank">Carpe Social</a>
              </li>
              <li>
                <a href="https://carpedmdating.com/black-women-deserve/" target="_blank">Black Women Deserve</a>
              </li>
              <li>
                <a href="https://carpedmdating.com/community-guidelines-2/" target="_blank">Community Guidelines</a>
              </li>
              <li>
                <a href="https://carpedmdating.com/we-give-black/" target="_blank">Donation Partners</a>
              </li>
              <li>
                <a href="https://carpedmdating.com/wp-content/uploads/2021/02/terms-of-use.pdf" target="_blank">Terms of Use</a>
              </li>
              <li>
                <a href="https://carpedmdating.com/faqs/" target="_blank">FAQs</a>
              </li>
            </ul>
          </div>

          <!-- social links -->
          <div class="social-links">
            <strong><p>Contact Us</p></strong>
            <ul>
              <li>
                <a href="mailto:Ask@carpedmdating.com?subject=I have a membership question">@ membership</a>
              </li>
              <li>
                <a href="mailto:Billing@carpedmdating.com?subject=I have a billing question">@ billing</a>
              </li>
            </ul>
          </div>

          <!-- log out -->
          <div class="logout">
            <p style="cursor: pointer" v-on:click="logout()">Logout</p>
          </div>
        </div>
      </b-col>

      <!-- body -->
      <b-col class="main-body" lg="6" cols="12">
        <!-- main -->
        <b-row>
          <b-col>
            <div class="main-info">
              <h3 class="main-info-title">ACCOUNT DETAILS</h3>
              <p>
                <a class="btn-utility-bold-links" v-b-modal.modal-3>Update Contact Information</a>
              </p>
              <br />
              <p class="main-username">
                {{ results.firstName ? results.firstName : '' }}
                {{ results.lastName ? results.lastName : '' }}
              </p>
              <p class="main-phone-number">
                {{ results.phoneNumber ? results.phoneNumber : '' }}
              </p>
              <p class="main-email">
                {{ results.emailAddress ? results.emailAddress : '' }}
              </p>
            </div>
          </b-col>
        </b-row>

        <!-- matchmaker -->
        <div class="section">
          <b-row>
            <b-col>
              <div class="main-matchmaker">
                <h3 class="main-matchmaker-title">YOUR MATCHMAKER</h3>
                <p>
                  <a
                    class="btn-utility-bold-links"
                    :href="`mailto:ask@carpedmdating.com?subject=Hey ${results.matchmaker.name}, ${results.firstName} ${results.lastName} has a question for you.`"
                    >Message Matchmaker</a
                  >
                </p>
                <br />
                <div class="main-matchmaker">
                  <div class="matchmaker-img">
                    <img v-bind:src="results.matchmaker.photoUrl" />
                  </div>
                  <p>{{ results.matchmaker ? results.matchmaker.name : null }}</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- member -->
        <div class="section">
          <b-row>
            <b-col lg="12" cols="12">
              <div class="main-member">
                <h3 class="main-member-title">MEMBERSHIP & PAYMENTS</h3>
                <p class="btn-utility-bold-links" v-on:click.prevent="customerPortal(sessionId)">Manage Membership</p>
                <br />
                <br />
                <p class="main-member-type">
                  MEMBERSHIP TYPE:
                  {{ membershipInfo.membershipType ? membershipInfo.membershipType : 'Membership Type Not Found' }}
                </p>
                <br />
                <div class="main-renew">
                  <strong><p class="main-renew-title">Membership Renewal</p></strong>
                  <p class="main-renew-status">
                    {{ membershipInfo.nextPayment ? membershipInfo.nextPayment : '' }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Background Check -->
        <div class="section">
          <h3 class="main-background-title">BACKGROUND CHECK</h3>
          <b-row>
            <b-col>
              <div class="main-background">
                <p>
                  <a class="btn-utility-bold-links" target="_blank" href="https://app.karmacheck.com/">Access Results</a>
                </p>
                <br />
                <p>
                  Status:
                  <span class="main-background-status">{{ backgroundCheckStatus ? backgroundCheckStatus : 'Not Found' }}</span>
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <!-- Side Bar -->
      <b-col class="sidebar" lg="3">
        <!-- Referrals -->
        <div class="section no-top hide">
          <h3 class="main-referral">REFERRAL LINK</h3>
          <b-row>
            <b-col>
              <div class="main-referral">
                <p>Share your referral link with friends to earn cash. You’ll earn $100 for every friend who becomes a member.</p>
                <br />
                <div>
                  <input
                    readonly
                    :value="results.rewardful ? results.rewardful.rewardfulReferralLink : 'loading...'"
                    id="referral-link"
                    class="referral-link"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Credits -->
        <div class="section section-block hide">
          <h3 class="main-credits-title">REFERRAL CREDITS</h3>
          <b-row>
            <b-col>
              <div class="main-credits">
                <p v-if="showCommission">
                  <span class="main-credits-amount">${{ results.rewardful ? results.rewardful.rewardfulCommission : 'loading...' }}</span>
                </p>
                <b-button id="cash-out-modal" v-on:click="getUpdatedCommission()">{{ showCommission ? 'Hide Credits' : 'View Credits' }}</b-button
                ><br />
                <b-button id="cash-out-modal" v-b-modal="'cash-out-modal'">Cash Out</b-button>
                <CashOutModal v-bind:firstName="results.firstName" v-bind:lastName="results.lastName" v-bind:commission="commission" />
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Perks -->
        <div class="main-perks-container section no-bottom section-block" style="padding-top: 0">
          <h3 class="main-perks-title">MEMBER PERKS</h3>
          <p>Enjoy these special offers and discounts from our partners.</p>
          <b-row>
            <b-col>
              <hooper :vertical="true" :itemsToShow="4" :itemsToSlide="4" :trimWhiteSpace="true" :centerMode="false">
                <slide v-for="results in promotionResults" :key="results.id">
                  <img style="width: 100%" v-b-modal="results.id" :src="results.businessImage" />
                  <b-modal hide-footer :id="results.id" v-bind:title="results.businessName">
                    <img style="width: 100%" v-b-modal="results.id" v-bind:src="results.businessImage" />
                    <p class="my-4">{{ results.businessDescription }}</p>
                    <p class="my-4">{{ results.businessPerks }}</p>
                    <a class="btn-login-primary" target="_blank" v-bind:href="'http://' + results.businessUrl">Visit Website</a>
                  </b-modal>
                </slide>
                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <!-- body -->
      <b-col class="mobile-main-body" lg="6" cols="12">
        <!-- main -->
        <b-row>
          <b-col>
            <div class="main-info">
              <h3 class="main-info-title">ACCOUNT DETAILS</h3>
              <p>
                <a class="btn-utility-bold-links" v-b-modal.modal-3>Update Contact Information</a>
              </p>
              <br />
              <p class="main-username">
                {{ results.firstName ? results.firstName : '' }}
                {{ results.lastName ? results.lastName : '' }}
              </p>
              <p class="main-phone-number">
                {{ results.phoneNumber ? results.phoneNumber : '' }}
              </p>
              <p class="main-email">
                {{ results.emailAddress ? results.emailAddress : '' }}
              </p>
            </div>
          </b-col>
        </b-row>

        <!-- matchmaker -->
        <div class="section">
          <b-row>
            <b-col>
              <div class="main-matchmaker">
                <h3 class="main-matchmaker-title">YOUR MATCHMAKER</h3>
                <p>
                  <a class="btn-utility-bold-links" href="#">Message Matchmaker</a>
                </p>
                <br />
                <div class="main-matchmaker">
                  <div class="matchmaker-img">
                    <img v-bind:src="results.matchmaker.photoUrl" />
                  </div>

                  <p>{{ results.matchmaker ? results.matchmaker.name : null }}</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- member -->
        <div class="section">
          <b-row>
            <b-col>
              <div class="main-member">
                <h3 class="main-member-title">MEMBERSHIP & PAYMENTS</h3>
                <p class="btn-utility-bold-links" v-on:click="customerPortal(sessionId)">Manage Membership</p>
                <br />
                <br />
                <p class="main-member-type">
                  MEMBERSHIP TYPE:
                  {{ membershipInfo.membershipType ? membershipInfo.membershipType : 'Membership Type Not Found' }}
                </p>
                <br />
                <div class="main-renew">
                  <strong><p class="main-renew-title">Membership Renewal</p></strong>
                  <p class="main-renew-status">
                    {{ results.membershipDuration ? results.membershipDuration : '' }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Background Check -->
        <div class="section">
          <h3 class="main-background-title">BACKGROUND CHECK</h3>
          <b-row>
            <b-col>
              <div class="main-background">
                <p>
                  <a class="btn-utility-bold-links" v-bind:href="url">Access Results</a>
                </p>
                <br />
                <p>
                  Status:
                  <span class="main-background-status">{{ backgroundCheckStatus ? backgroundCheckStatus : 'Not Found' }}</span>
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <!-- Mobile Footer -->

    <b-row>
      <!-- left bar -->
      <b-col class="mobile-ultility-bar" cols="12">
        <div class="footer-links">
          <!-- social links -->
          <div class="social-links">
            <strong><p>Contact Us</p></strong>
            <ul>
              <li><a v-bind:href="url" target="_blank">@ membership</a></li>
              <li><a v-bind:href="url" target="_blank">@ billing</a></li>
            </ul>
          </div>
          <div class="utility-links info-links">
            <ul>
              <li><a v-bind:href="url" target="_blank">Terms of Use</a></li>
              <li><a v-bind:href="url" target="_blank">FAQs</a></li>
            </ul>
          </div>

          <!-- log out -->
          <div class="logout">
            <p v-on:click="logout()">Logout</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- End Footer -->
  </div>
</template>

<script>
import endpoints from '../../utils/endpoints';
import { authGet, basicPost, authPost } from '../../utils/fetchApi';
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import UpdateContactModal from './UpdateContactModal';
import { resizeImgQualityAdvanced } from '../../utils/cloudinary';
// import CashOutModal from './CashOutModal.vue'
import 'hooper/dist/hooper.css';

export default {
  data: function () {
    return {
      url: 'https://www.example.com',
      results: [],
      backgroundCheckStatus: null,
      promotionResults: null,
      referralResults: null,
      referralLink: '',
      commission: null,
      referralConversions: '',
      sessionId: null,
      member: null,
      showCommission: false,
      membershipInfo: {
        membershipType: this.membershipType,
        membershipDuration: this.membershipDuration,
        affiliateId: this.affiliateId,
        nextPayment: this.nextPayment,
        oldReferralLink: this.oldReferralLink,
      },
      menu: {
        homeLink: 'https://www.example.com',
      },
      config: {
        vue_app_env: this.$config.vue_app_env,
        vue_app_host: this.$config.vue_app_host,
      },
    };
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    UpdateContactModal,
    // CashOutModal
  },
  mounted: function () {
    const dataObj = {};
    this.getUserData(dataObj);
    this.getMembershipPaymentData(dataObj);
    this.getPromotions();
    this.checkBackgroundCheck();

    setTimeout(() => {
      this.updateRewardfulObj();
    }, 2500);
  },
  methods: {
    updateRewardfulObj() {
      const vu = localStorage.getItem('vu');
      const u = localStorage.getItem('u');

      const body = {
        rewardfulAffiliateId: this.membershipInfo.affiliateId ? this.membershipInfo.affiliateId : 'string',
        rewardfulCommission: this.commission ? this.commission : 'string',
        rewardfulReferralLink: this.membershipInfo.oldReferralLink ? this.membershipInfo.oldReferralLink : 'string',
      };

      // if user is an affiliate but doesnt have info in the new rewardful object
      if (this.results.rewardful === null && this.membershipInfo.affiliateId !== 'string') {
        // update new rewardful object with old affiliate info
        authPost('post', endpoints.updateRewardfulObj(this.config.vue_app_env, u), vu, body)
          .then((response) => response.text())
          .then((data) => {
            console.log('rewardful obj updated');
            return data;
          })
          .catch((error) => {
            console.log('Error:', error);
          });
      }
    },
    copyLink() {
      const referralLink = document.getElementById('referral-link');
      referralLink.select();
      document.execCommand('copy');
    },
    logout() {
      localStorage.removeItem('u');
      localStorage.removeItem('vu');
      this.$router.push('/login');
    },
    customerPortal(id) {
      // FETCH FROM SERVER ENDPOINT
      fetch(`${this.config.vue_app_host}/api/customer-portal/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          window.location.href = data.url;
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    getUserData(dataObj) {
      const u = localStorage.getItem('u');
      const vu = localStorage.getItem('vu');

      authGet('get', endpoints.getUserById(this.config.vue_app_env, u), vu)
        .then((response) => response.json())
        .then((data) => {
          dataObj.firstName = data.firstName;
          dataObj.lastName = data.lastName;
          dataObj.phoneNumber = data.phoneNumber;
          dataObj.emailAddress = data.emailAddress;
          dataObj.profilePic = data.media.photoUrls !== null ? resizeImgQualityAdvanced(data.media.profilePictureUrl, 90, 'jpg', '240', '240', false) : '#';
          dataObj.matchmaker = data.matchmaker;
          dataObj.matchmaker.photoUrl = resizeImgQualityAdvanced(data.matchmaker.photoUrl, 90, 'jpg', '240', '240', false);
          dataObj.rewardful = data.rewardful;
          this.results = dataObj;
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    checkBackgroundCheck() {
      const vu = localStorage.getItem('vu');
      // Check if background check is "Complete"
      authGet('get', endpoints.getBackgroundCheck(this.config.vue_app_env), vu)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.backgroundCheckStatus = data.status;
            return this.backgroundCheckStatus;
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    getPromotions() {
      const vu = localStorage.getItem('vu');
      authGet('get', endpoints.getPromotion(this.config.vue_app_env), vu)
        .then((response) => response.json())
        .then((data) => {
          // parse images
          data.forEach((item) => {
            item.businessImage = resizeImgQualityAdvanced(item.businessImage, 90, 'jpg', '475', '475', false);
          });
          this.promotionResults = data;
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    getMembershipPaymentData() {
      const vu = localStorage.getItem('vu');

      // const getsessionId = `${this.config.vue_app_env}/api/Portal/get-payment-info/`;

      authGet('get', endpoints.getPaymentInfo(this.config.vue_app_env), vu)
        .then((response) => response.json())
        .then((data) => {
          this.sessionId = data.membership.payments[0].sessionId;
          this.membershipInfo.membershipType = data.membership.membershipType;
          this.membershipInfo.membershipDuration = data.membershipDuration;
          this.membershipInfo.affiliateId = data.membership.payments[0].affiliateId;
          this.commission = data.membership.payments[0].method;
          this.oldReferralLink = data.membership.payments[0].invoices[0].path;
          this.membershipInfo.nextPayment = new Date(data.membership.payments[0].nextPayment).toUTCString().split(' ').slice(0, 4).join(' ');
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    getUpdatedCommission() {
      console.log('get commission updates');
      this.showCommission = !this.showCommission;
      const affiliateId = this.membershipInfo.affiliateId;

      const body = {
        affiliateId,
      };

      basicPost('post', endpoints.getRewardfulUserInfo(), body)
        .then((response) => response.json())
        .then((data) => {
          if (data.amount === 'undefined') {
            return data;
          } else {
            this.commission = data.amount;
          }
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
  },
};
</script>

<style scoped>
@keyframes phaseIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Matchmaker */
.main-matchmaker .matchmaker-img {
  background: rgb(0, 0, 0);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  overflow: hidden;
}

.main-matchmaker .matchmaker-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/* Referral Link */
.copy-icon {
  width: 25px;
  margin-left: 20px;
  position: relative;
  top: -2px;
  cursor: pointer;
}

.copy-icon path {
  fill: var(--primary-green);
}

.referral-link {
  color: var(--primary-green);
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 0;
  width: 100%;
  font-size: 0.8em;
}

.referral-link:focus-visible {
  outline: none;
}

.main-credits-amount {
  font-size: 2.5em;
}

/* containers */
.outer-container {
  width: 95% !important;
  margin: auto;
}

.section {
  padding: 50px 0 50px 0;
  border-bottom: 1px solid black;
}

/* general */
p {
  margin-bottom: 0px;
}

a {
  color: var(--primary-green);
}

h3 {
  font-weight: bold;
}

/* Navigation */
.nav-container {
  padding-bottom: 50px;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: flex-end;
}

.nav-menu ul a {
  color: black !important;
}

.nav-menu ul li {
  display: inline-block;
  padding-right: 60px;
}

.nav-menu ul li {
  cursor: pointer;
}

/* center panel - body */
.dashboard-container {
  height: 100%;
}

.main-body {
  padding: 35px 50px 0 50px;
  background: #ececec;
  border-left: 20px solid white;
  border-right: 20px solid white;
}

.mobile-main-body {
  display: none;
}

.main-info {
  padding-bottom: 25px;
  border-bottom: 1px solid black;
}

/* right panel - sidebar */

.hide {
  display: none;
}

.sidebar {
  background: #ececec;
  padding: 35px 50px;
  width: 100%;
}

.no-top {
  padding: 0 0 25px 0;
  border-bottom: 1px solid black;
}

.no-bottom {
  border-bottom: none;
}

.main-perks-container {
  padding-bottom: 0;
}

.main-perks {
  display: flex;
  padding: 20px 0;
}

.perks-img {
  width: 45%;
  list-style: none;
  height: 80px;
}

.perks-img img {
  width: 90%;
}

/* left panel - utility bar */
.welcome {
  border-bottom: 1px solid var(--primary-white);
}

.welcome-img {
  display: flex;
  justify-content: center;
  background: black;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: auto;
  overflow: hidden;
}

.welcome-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.welcome-caption {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-white);
  padding: 25px 0;
}

.welcome-caption p {
  padding: 0;
  margin: 0;
}

#links {
  padding: 0 0 0 30px;
}

#utility-bar {
  background: var(--primary-blue);
  padding: 35px 50px;
}

.utility-links ul {
  padding: 50px 0 0 0;
}

.utility-links ul li {
  list-style: none;
  padding-bottom: 20px;
}

.utility-links ul li > a {
  color: var(--primary-white);
}

.social-links p {
  margin: 0;
}

.social-links ul {
  padding: 0 0 0 0;
}

.social-links ul li {
  list-style: none;
}

.social-links p,
.social-links a,
.logout p {
  color: white;
}

.mobile-ultility-bar {
  width: 100%;
  display: none;
}

.logout {
  padding-top: 30px;
}

/* Cash out */
#cash-out-modal {
  border-radius: 0;
  border: 0;
  background: var(--primary-green);
  margin-top: 10px;
}

#cash-out-modal:hover {
  background: var(--primary-green-hover);
}

@media only screen and (max-width: 991px) {
  .app-container {
    width: 100%;
  }

  .outer-container {
    width: 100% !important;
    margin: auto;
  }

  .welcome-img {
    display: flex;
    justify-content: center;
    background: black;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    margin: auto;
    overflow: hidden;
  }

  .welcome-caption {
    font-size: 1.5rem;
  }

  #utility-bar {
    padding: 35px 25px;
  }

  .utility-links ul li {
    text-align: center;
    padding-bottom: 10px;
  }

  .utility-links ul {
    padding: 0;
  }

  #utility-bar[data-v-aae30ed8] {
    padding: 0px 25px;
  }

  .info-links {
    border-bottom: 1px solid var(--primary-white);
  }

  #links {
    padding: 50px 0 50px 0;
  }

  .social-links {
    text-align: center;
  }

  .logout {
    padding: 20px 0 0 0;
  }

  .section-block {
    border: none;
    position: relative;
  }

  .sidebar {
    border-top: 10px solid var(--primary-white);
  }

  .section-block:after {
    content: '';
    border: none;
    position: absolute;
    bottom: -10px;
    left: -25px;
    width: 100vw;
    height: 10px;
    background: white;
    z-index: 99;
  }

  .main-body {
    padding: 35px 50px 0 50px;
    background: #ececec;
    border-left: none;
    border-right: none;
    display: none;
  }

  .mobile-main-body {
    display: block;
    background: #ececec;
    padding: 35px 35px;
    border-bottom: 10px solid var(--primary-white);
  }

  .main-perks {
    padding: 20px 0 20px 0;
  }

  .main-perks .perks-img {
    flex: 35%;
    text-align: center;
    height: 60px !important;
  }

  .main-perks .perks-img img {
    flex: 100%;
  }

  .logout {
    padding: 20px 0 20px 0;
  }

  .logout p {
    text-align: center;
  }

  .mobile-ultility-bar {
    width: 100%;
    display: block;
    background: var(--primary-blue);
    padding: 35px 0 0 0;
  }

  .desktop-ultility-bar {
    width: 100%;
    display: none;
  }

  .dashboard-container {
    height: auto;
  }

  .nav-menu {
    display: none;
  }

  #links {
    padding: 25px 0 0 0;
  }

  .footer-links {
    padding: 0 25px;
  }

  .section {
    padding: 25px 0 25px 0;
  }

  .section:last-child {
    border: none;
  }

  .main-perks-container,
  .mobile-ultility-bar {
    padding: 35px 0 0 0;
  }

  .sidebar {
    padding: 0 25px;
  }

  .referral-link {
    width: 50%;
  }
}
</style>
