<template>
	<div id="deposit-form">
		<div>
			<b-row>
				<b-col cols="3"></b-col>
				<b-col class="logo-container" cols="6" style="text-align: center;">
					<a href="https://carpedmdating.com/"><img src="@/assets/carpedm-color-logo.png"/></a>
				</b-col>
				<b-col cols="3"></b-col>
			</b-row>
			<b-row>
				<b-col class="content-container" style="text-align:center">
					<div>
						<h1>Welcome to CarpeDM</h1>
						<p>
							Now that you're a member, download the CarpeDM iOS app to receive and engage with your matches. You also get access to exclusive content
							through our member web portal.
						</p>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="2" md="4"></b-col>
				<b-col cols="8" md="4" style="text-align:center">
					<div>
						<div class="d-sm-none getAppBtn d-none d-sm-none d-md-block" v-on:click="getApp()">Text Me the App</div>
						<a target="_blank" href="https://apps.apple.com/us/app/carpedm-dating/id1383599767"
							><div class="d-sm-none getAppBtn d-block d-md-none d-sm-block">Open App</div></a
						>
						<p style="cursor:pointer; color:#15bda5;" v-on:click="accessDashboard()">Explore Membership Portal</p>
					</div>
				</b-col>
				<b-col cols="2" md="4"></b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
	import endpoints from "../../utils/endpoints";
	import { authPost } from "../../utils/fetchApi";
	import { mapGetters } from "vuex";
	import store from "../../store/index";
	export default {
		data: function() {
			return {
				url: "www.example.com",
				config: {
					vue_app_env: this.$config.vue_app_env,
				},
				checkoutPaymentPrice: store.state.checkoutPaymentPrice,
			};
		},
		mounted: function() {},
		methods: {
			getApp() {
				const vu = localStorage.getItem("vu");

				authPost("post", endpoints.downloadLink(this.config.vue_app_env), vu)
					.then((response) => response.text())
					.then((data) => {
						return data;
					})
					.catch((error) => {
						console.log("Error:", error);
					});
			},
			accessDashboard() {
				this.$router.push("/dashboard");
			},
			computed: mapGetters(["getPaymentPrice"]),
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	#deposit-form {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	a {
		color: #15bda5;
	}

	.content-container div p {
		width: 40%;
		margin: auto;
		padding-top: 20px;
	}

	.content-container div {
		padding: 50px;
	}

	.getAppBtn {
		cursor: pointer;
		width: 100%;
		margin: auto;
		margin: 20px 0 20px 0;
		padding: 10px 20px;
		background: #15bda5;
		color: white;
	}

	.logo-container img {
		width: 40%;
		display: inline;
	}

	@media only screen and (max-width: 991px) {
		.logo-container img {
			width: 100%;
			display: inline;
		}

		.content-container div {
			padding: 0;
		}

		.content-container div p {
			width: 80%;
		}
	}
</style>
