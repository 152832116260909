<template>
	<div id="deposit-form">
		<div class="success-container">
			<div class="success-message" style="display:flex; flex-direction:column; text-align: center;">
				<h1>Make Your Deposit</h1>
				<form id="success-form">
					<p class="btn-status-page-primary" v-on:click.prevent="checkout()">Make Payment</p>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import endpoints from "../../utils/endpoints";
	import { authGet } from "../../utils/fetchApi";
	import { fbPixel } from "../../utils/fbPixel";

	let stripe;
	process.env.NODE_ENV === "production"
		? (stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_PUBLISH_KEY}`))
		: (stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_PUBLISH_KEY_TEST}`));

	export default {
		data: function() {
			return {
				memberInfo: {
					firstName: null,
					lastName: null,
					email: null,
					auth: localStorage.getItem("vu"),
					checkoutType: "Deposit",
				},
				config: {
					vue_app_env: this.$config.vue_app_env,
					vue_app_host: this.$config.vue_app_host,
					vue_app_deposit_price_id: this.$config.product_ids.vue_app_deposit_price_id,
				},
			};
		},
		mounted: function() {
			const vu = localStorage.getItem("vu");
			this.isDepositPaid(vu);
			this.getData();
			this.isCustomer();
		},
		methods: {
			isDepositPaid(vu) {
				authGet("get", endpoints.getDepositStatus(this.config.vue_app_env), vu)
					.then((response) => response.json())
					.then((data) => {
						return data;
					})
					.catch((error) => {
						console.log("Error:", error);
					});
			},
			getData() {
				const u = localStorage.getItem("u");
				const vu = localStorage.getItem("vu");
				authGet("get", endpoints.getUserById(this.config.vue_app_env, u), vu)
					.then((response) => response.json())
					.then((data) => {
						this.memberInfo.firstName = data.firstName;
						this.memberInfo.lastName = data.lastName;
						this.memberInfo.email = data.emailAddress;
					})
					.catch((error) => {
						console.log("Error:", error);
					});
			},
			checkout() {
				// deposit price id
				const priceId = `${this.config.vue_app_deposit_price_id}`;

				const body = {
					firstName: this.memberInfo.firstName,
					lastName: this.memberInfo.lastName,
					email: this.memberInfo.email,
					auth: this.memberInfo.auth,
					priceId,
					checkoutType: this.memberInfo.checkoutType,
				};

				// Create a new Checkout Session using the server-side endpoint you
				fetch(`${this.config.vue_app_host}/api/create-checkout-session`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(body),
				})
					.then(function(response) {
						return response.json();
					})
					.then(function(session) {
						return stripe.redirectToCheckout({
							sessionId: session.id,
						});
					})
					.then(function(result) {
						// If `redirectToCheckout` fails due to a browser or network
						// error, you should display the localized error message to your
						// customer using `error.message`
						if (result.error) {
							alert(result.error.message);
						}
						
						fbPixel('purchase', priceId)
					})
					.catch(function(error) {
						console.error("Error:", error);
					});
			},
			isCustomer() {
				const vu = localStorage.getItem("vu");
				authGet("get", endpoints.getPaymentInfo(this.config.vue_app_env), vu)
					.then((response) => response.json())
					.then((data) => {
						return data;
					})
					.catch((error) => {
						console.log("Error:", error);
					});
			},
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	#deposit-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
</style>
