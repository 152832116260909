<template>
	<div
		style="
    padding: 20px;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    z-index: 99;
    background: #15bda5;
    color: white;
    text-align: center;
    animation: fadeIn .8s ease;
    "
		v-if="warningZone"
	>
		Are you still here?
	</div>
</template>

<script>
	export default {
		name: "AutoLogout",
		data: function() {
			return {
				events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
				warningTimer: null,
				logoutTimer: null,
				warningZone: false,
			};
		},
		mounted() {
			this.events.forEach((event) => window.addEventListener(event, this.resetTimer), this);

			this.setTimers();
		},
		destroyed() {
			this.events.forEach((event) => window.removeEventListener(event, this.resetTimer), this);
			this.resetTimers();
		},
		methods: {
			resetTimer: function() {
				clearTimeout(this.warningTimer);
				clearTimeout(this.logoutTimer);
				this.setTimers();
			},
			setTimers: function() {
				this.warningTimer = setTimeout(this.warningMessage, 14 * 60 * 1000);
				this.logoutTimer = setTimeout(this.logout, 15 * 60 * 1000);

				this.warningZone = false;
			},
			warningMessage: function() {
				this.warningZone = true;
			},
			logout() {
				localStorage.removeItem("u");
				localStorage.removeItem("vu");
				this.$router.push("/login");
			},
		},
	};
</script>

<style>
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
