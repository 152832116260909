<template>
	<b-form
		v-on:submit.prevent="resetPassword(input_reset_password, input_reset_passcode, input_confirmed_password)"
		class="reset-form input-container"
	>
		<div class="inner-container">
			<h1>Reset Password</h1>
			<!-- <div>
				<label class="sr-only" for="inline-form-input-name">Email</label>
				<b-form-input
					required
					class="inline-form-input-name mb-2 mr-sm-2 mb-sm-0"
					placeholder="enter your email"
					type="email"
					name="email"
					v-model="localInputEmail"
					readonly
					v-on:input="resetErrorBlock()"
				></b-form-input>
			</div> -->

			<div>
				<label class="sr-only" for="inline-form-input-name">Password</label>
				<b-form-input
					required
					class="inline-form-input-name mb-2 mr-sm-2 mb-sm-0"
					placeholder="enter your password"
					type="password"
					name="reset-password"
					v-model="input_reset_password"
					v-on:input="resetErrorBlock()"
				></b-form-input>
			</div>
			<div>
				<label class="sr-only" for="inline-form-input-name">Confirm Password</label>
				<b-form-input
					required
					class="inline-form-input-name mb-2 mr-sm-2 mb-sm-0"
					placeholder="confirm your password"
					type="password"
					name="confirmed-password"
					v-model="input_confirmed_password"
					v-on:input="resetErrorBlock()"
				></b-form-input>
			</div>
			<div>
				<p>We sent a verification code via SMS to the phone number linked to your account. Enter the code below to continue.</p>
				<b-form-input
					required
					id="resetPasscode"
					class="inline-form-input-name mb-2 mr-sm-2 mb-sm-0"
					placeholder="enter new passcode"
					type="password"
					name="resetPasscode"
					v-model="input_reset_passcode"
					v-on:input="resetErrorBlock()"
				></b-form-input>
			</div>

			<b-button type="submit" variant="primary" class="btn-login-primary">Reset</b-button>
			<b-button class="btn-login-primary" v-on:click="reloadPage()" type="button">Cancel</b-button>
		</div>
	</b-form>
</template>

<script>
	export default {
		data() {
			return {
				localInputEmail: this.inputEmail,
				input_reset_password: this.inputResetPassword,
				input_confirmed_password: this.inputConfirmedPassword,
				input_reset_passcode: this.inputResetPasscode,
			};
		},
		props: {
			inputEmail: { type: String },
			resetPassword: { type: Function },
			inputResetPassword: { type: String },
			inputConfirmedPassword: { type: String },
			inputResetPasscode: { type: String },
			reloadPage: { type: Function },
			resetErrorBlock: { type: Function },
		},
		
	};
</script>

<style></style>
